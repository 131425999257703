import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
	CustomInputType,
	DefaultValueGetter,
	DefaultValueGettersObject
} from "@remar/shared/dist/components/CustomInput/customInput.model";
import { ExternalIntegrationIds, UserSubscriptionTypeCategoriesEnum, UserTypes } from "@remar/shared/dist/constants";
import {
	AppleIntegrationData,
	DigitalAssetIntegrationData,
	ShipStationIntegrationData,
	StripeIntegrationData,
	UserSubscriptionTypeExternalIntegrationDataItem
} from "@remar/shared/dist/models";
import {
	BaseSubItemFormInputsRawData,
	CreateFormOptionsInputItem,
	CreateFormOptionsInputsObject
} from "@remar/shared/dist/utils/form/form.model";
import {
	createForm,
	getBaseCreateFormSubItemConfig,
	getPopulateInputsAction,
	parseTemplate,
	validateFormAction as utilsValidateFormAction
} from "@remar/shared/dist/utils/form/form.utils";
import {
	getResetState,
	getStateValue as utilsGetStateValue,
	populateData as utilsPopulateData,
	setStateValue as utilsSetStateValue
} from "@remar/shared/dist/utils/stateUtils";
import { cloneDeep, get, set } from "lodash";
import { AppThunk, RootState } from "store";
import {
	CourseCreateDto,
	CourseUpdateDataDto,
	UserSubscriptionTypeExternalIntegrationDataItemUpsertDto,
	coursesService,
	userSubscriptionTypesService
} from "store/services";
import { v4 as uuid } from "uuid";

import { PAYMENT_CONSTANTS, STATE_BASE_NAME, STRIPE_CURRENCY_SYMBOL_MAP } from "./courseAddEdit.constants";
import {
	CourseAddEditState,
	CourseFeaturesFormInputs,
	CourseFeaturesFormRawData,
	CourseFormInputs,
	CourseFormRawData,
	DefaultCourseItem,
	UserSubscriptionTypesFormAddonInputsItem,
	UserSubscriptionTypesFormAddonInputsItemRawData,
	UserSubscriptionTypesFormInputs,
	UserSubscriptionTypesFormInputsItem,
	UserSubscriptionTypesFormRawData,
	UserSubscriptionTypesFormRawDataItem,
	UserSubscriptionTypesFormTemplates
} from "./models";

import { _emit, emit } from "../notifications/notifications.slice";

const defaultValueGetters: DefaultValueGettersObject = {
	uuidV4: (() => uuid()) as DefaultValueGetter
};

export function getDurationStringFromDuration(duration: { months?: number; days?: number }): string {
	const { days, months } = duration;
	let durationString = "";
	if (months) {
		durationString += `${months} months`;
	}
	if (days) {
		durationString += `${durationString.length ? " " : ""}${days} days`;
	}
	return durationString;
}

export function getDigitalAssetInputsConfig(options: { isRequired: boolean }): {
	[inputName: string]: CreateFormOptionsInputItem<unknown>;
} {
	const { isRequired } = options;
	return {
		...getBaseCreateFormSubItemConfig(),
		isLoading: {
			defaultValue: false,
			type: CustomInputType.Checkbox
		},
		name: { type: CustomInputType.Text },
		productKey: {
			imageFileType: "application/pdf",
			imageExtensionName: "pdf",
			isImageFile: false,
			label: "Upload Digital Asset",
			placeholder: "Upload Digital Asset",
			type: CustomInputType.File,
			uploaderIconName: "cloud-upload",
			uploaderStateLoaderKey: "saveIsLoading",
			validations: { required: isRequired }
		}
	};
}

export function getShipStationInputsConfig(options: { isRequired: boolean }): {
	[inputName: string]: CreateFormOptionsInputItem<unknown>;
} {
	const { isRequired } = options;
	return {
		...getBaseCreateFormSubItemConfig(),
		isLoading: {
			defaultValue: false,
			type: CustomInputType.Checkbox
		},
		name: { type: CustomInputType.Text },
		productSKU: {
			label: "Paste Id Here",
			placeholder: "Paste Id Here",
			type: CustomInputType.Text,
			validations: isRequired ? { required: true } : undefined
		},
		clientSKU: {
			label: "Paste Id Here",
			placeholder: "Paste Id Here",
			type: CustomInputType.Text,
			validations: isRequired ? { required: true } : undefined
		}
	};
}

export function getAppleProductInputsConfig(options: { isRequired: boolean }): {
	[inputName: string]: CreateFormOptionsInputItem<unknown>;
} {
	const { isRequired } = options;
	return {
		...getBaseCreateFormSubItemConfig(),
		isLoading: {
			defaultValue: false,
			type: CustomInputType.Checkbox
		},
		name: { type: CustomInputType.Text, validations: isRequired ? { required: true } : undefined },
		duration: { type: CustomInputType.Text },
		productKey: {
			label: "Paste Id Here",
			placeholder: "Paste Id Here",
			type: CustomInputType.Text
		}
	};
}

function getStripeInputsConfig(options: { isRequired: boolean }): {
	[inputName: string]:
		| CreateFormOptionsInputItem<unknown>
		| { [inputName: string]: CreateFormOptionsInputItem<unknown> }[];
} {
	const { isRequired } = options;
	return {
		...getBaseCreateFormSubItemConfig(),
		duration: { type: CustomInputType.Text },
		isLoading: {
			defaultValue: false,
			type: CustomInputType.Checkbox
		},
		name: { type: CustomInputType.Text },
		planId: {
			label: "Paste Link Here",
			placeholder: "Paste Link Here",
			type: CustomInputType.Text,
			validations: isRequired ? { required: true } : undefined
		},
		price: { defaultValue: 0, type: CustomInputType.Number },
		counterpartIds: [getBaseCreateFormSubItemConfig()]
	};
}

// this object must follow the structure of UserSubscriptionTypesFormInputsItem
function getSubscriptionInputsConfig(
	key: string,
	options: {
		isRecurring: boolean;
		isRequired: boolean;
		isTrial: boolean;
		withAddons?: boolean;
		withInitialBook?: boolean;
		withQuestionBankOnly?: boolean;
		isAppleSubscription?: boolean;
	}
): CreateFormOptionsInputsObject {
	const { isRecurring, isRequired, isTrial, withAddons, withInitialBook, withQuestionBankOnly, isAppleSubscription } =
		options;
	const item: CreateFormOptionsInputsObject = {
		id: { type: CustomInputType.Number },
		draftId: {
			type: CustomInputType.Text,
			useDefaultValueGetterName: "uuidV4"
		},
		nextSubscriptionTypeId: { type: CustomInputType.Number },
		nextSubscriptionTypeDraftId: { type: CustomInputType.Text },
		isRecurring: {
			defaultValue: isRecurring,
			type: CustomInputType.Checkbox
		},
		isDigital: {
			defaultValue: false,
			label: "Digital Asset",
			type: CustomInputType.Checkbox
		},
		isDefault: {
			defaultValue: false,
			label: "Set As Most Popular",
			type: CustomInputType.Checkbox
		},
		isTrial: {
			defaultValue: isTrial,
			type: CustomInputType.Checkbox
		},
		stripeItem: getStripeInputsConfig({ isRequired }),
		addons: []
	};
	if (withQuestionBankOnly) {
		item.isQuestionBankOnly = {
			defaultValue: false,
			label: "Limit Access to Question Bank Only",
			type: CustomInputType.Checkbox
		};
	}
	if (isAppleSubscription) {
		item.appleProductItem = getAppleProductInputsConfig({ isRequired });
	}
	if (withInitialBook) {
		item.shipStationItem = getShipStationInputsConfig({ isRequired });
		item.digitalAssetItem = getDigitalAssetInputsConfig({ isRequired: false });
	}
	if (withAddons) {
		item.addons = [
			{
				shipStationItem: getShipStationInputsConfig({ isRequired }),
				digitalAssetItem: getDigitalAssetInputsConfig({ isRequired: false }),
				stripeItem: getStripeInputsConfig({ isRequired }),
				isDigital: {
					defaultValue: false,
					label: "Digital Asset",
					type: CustomInputType.Checkbox
				},
				isDefault: {
					defaultValue: false,
					label: "Selected by default",
					type: CustomInputType.Checkbox
				},
				mainImageKey: {
					imageExtensionName: "png",
					imageFileType: "image/png",
					isImageFile: true,
					label: "Upload  Media",
					placeholder: "Upload  Media",
					type: CustomInputType.File,
					uploaderIconName: "cloud-upload",
					uploaderStateLoaderKey: "uploading"
				},
				mainImageUrl: {
					type: CustomInputType.Text
				}
			}
		];
	}
	return item;
}

export const initialState: CourseAddEditState = {
	canSaveForm: false,
	courseForm: createForm<CourseFormInputs, CourseFormRawData, {}>({
		defaultValueGetters,
		inputs: {
			id: {
				type: CustomInputType.Number
			},
			name: {
				label: "Enter Course Title",
				placeholder: "Enter Course Title",
				type: CustomInputType.Text,
				validations: { required: true, maxLength: 140 }
			},
			description: {
				label: "Enter Course Description",
				placeholder: "Enter Course Description",
				type: CustomInputType.Text,
				validations: { maxLength: 150 }
			},
			endOfCourseSummary: {
				type: CustomInputType.Text
			},
			timeLengthHours: {
				defaultValue: 0,
				label: "Hours",
				placeholder: "Hours",
				type: CustomInputType.Number,
				validations: { isInteger: true, min: 0 }
			},
			timeLengthMinutes: {
				defaultValue: 0,
				label: "Minutes",
				placeholder: "Minutes",
				type: CustomInputType.Number,
				validations: { isInteger: true, min: 0 }
			},
			isActive: {
				defaultValue: false,
				type: CustomInputType.Checkbox
			},
			calculateTimeLengthAutomatically: {
				defaultValue: true,
				label: "Calculate Length Automatically",
				type: CustomInputType.Checkbox
			},
			freeShipping: {
				defaultValue: false,
				label: "Enable Free Shipping",
				type: CustomInputType.Checkbox
			},
			mainImageKey: {
				imageExtensionName: "png",
				imageFileType: "image/png",
				isImageFile: true,
				label: "Upload Thumbnail",
				placeholder: "Upload Thumbnail",
				type: CustomInputType.File,
				uploaderIconName: "camera",
				uploaderStateLoaderKey: "saveIsLoading"
			},
			mainImageUrl: {
				type: CustomInputType.Text
			}
		},
		statePath: "courseForm"
	}),
	data: {
		...DefaultCourseItem,
		externalIntegrationData: {
			initial: { isDigital: false, addons: [], isDefault: false },
			recurring: {},
			recurringQuestionBankOnly: {},
			trial: {},
			additionalInitial: [],
			additionalRecurring: []
		}
	},
	publishIsLoading: false,
	saveIsLoading: false,
	externalIntegrationData: {
		currency: "",
		planName: "",
		bookName: ""
	},
	userSubscriptionTypesForm: createForm<
		UserSubscriptionTypesFormInputs,
		UserSubscriptionTypesFormRawData,
		UserSubscriptionTypesFormTemplates
	>({
		defaultValueGetters,
		generateTemplatesFromPaths: [
			"initial.stripeItem.counterpartIds",
			"initial.addons",
			"initial.addons.0.stripeItem.counterpartIds",
			"recurring.stripeItem.counterpartIds",
			"recurringQuestionBankOnly.stripeItem.counterpartIds",
			"trial.stripeItem.counterpartIds",
			"additionalInitial",
			"additionalInitial.0.stripeItem.counterpartIds",
			"additionalRecurring",
			"additionalRecurring.0.stripeItem.counterpartIds",
			"appleInitial",
			"appleRecurring"
		],
		inputs: {
			initial: getSubscriptionInputsConfig("initial", {
				isRecurring: false,
				isRequired: true,
				isTrial: false,
				withAddons: true,
				withInitialBook: true,
				withQuestionBankOnly: true,
				isAppleSubscription: true
			}),
			recurring: getSubscriptionInputsConfig("recurring", {
				isRecurring: true,
				isRequired: true,
				isTrial: false
			}),
			appleInitial: getSubscriptionInputsConfig("appleInitial", {
				isRecurring: false,
				isRequired: false,
				isTrial: false,
				withAddons: false,
				withInitialBook: false,
				withQuestionBankOnly: false,
				isAppleSubscription: true
			}),
			appleRecurring: getSubscriptionInputsConfig("appleRecurring", {
				isRecurring: true,
				isRequired: false,
				isTrial: false,
				withAddons: false,
				withInitialBook: false,
				withQuestionBankOnly: false
			}),
			recurringQuestionBankOnly: getSubscriptionInputsConfig("recurringQuestionBankOnly", {
				isRecurring: true,
				isRequired: false,
				isTrial: false
			}),
			trial: getSubscriptionInputsConfig("trial", {
				isRecurring: false,
				isRequired: false,
				isTrial: true
			}),
			additionalInitial: [
				getSubscriptionInputsConfig("initial", {
					isRecurring: false,
					isRequired: true,
					isTrial: false,
					withAddons: false,
					withInitialBook: false,
					withQuestionBankOnly: true
				})
			],
			additionalRecurring: [
				getSubscriptionInputsConfig("recurring", {
					isRecurring: true,
					isRequired: true,
					isTrial: false
				})
			],
			hasTrial: {
				defaultValue: false,
				label: "Includes Free Trial",
				type: CustomInputType.Checkbox
			},
			hasQuestionBankOnlyConfiguration: {
				defaultValue: false,
				label: "Includes Question Bank Only Configuration",
				type: CustomInputType.Checkbox
			}
		},
		statePath: "userSubscriptionTypesForm"
	}),
	courseFeaturesForm: createForm<CourseFeaturesFormInputs, CourseFeaturesFormRawData, {}>({
		defaultValueGetters,
		inputs: {
			feature: {
				placeholder: "Enter Course Feature",
				type: CustomInputType.Text
			},
			additionalInfo: {
				placeholder: "Enter Additional Information",
				type: CustomInputType.Text,
				validations: { required: true, maxLength: 250 }
			}
		},
		statePath: "courseFeaturesForm"
	}),
	isLoading: false
};

const utilsPopulateInputs = getPopulateInputsAction<CourseAddEditState>({ defaultValueGetters });
const utilsResetState = getResetState<CourseAddEditState>(initialState);

export const courseAddEditSlice = createSlice({
	name: STATE_BASE_NAME,
	initialState,
	reducers: {
		setAsDefault: (state, action: PayloadAction<{ innerPath: string; value: boolean }>) => {
			const { innerPath, value } = action.payload;
			const initialOfferingPath = "userSubscriptionTypesForm.inputs.additionalInitial";
			const initialOfferings = get(state, initialOfferingPath) as UserSubscriptionTypesFormInputsItem[];
			initialOfferings.forEach(o => (o.isDefault.value = false));
			set(state, initialOfferingPath, initialOfferings);
			const initialPath = "userSubscriptionTypesForm.inputs.initial";
			const initial = get(state, initialPath) as UserSubscriptionTypesFormInputsItem;
			initial.isDefault.value = false;
			set(state, initialPath, initial);
			const targetPath = `userSubscriptionTypesForm.inputs.${innerPath}.isDefault.value`;
			set(state, targetPath, value);
		},
		setAsDefaultRecurring: (state, action: PayloadAction<{ innerPath: string; value: boolean }>) => {
			const { innerPath, value } = action.payload;
			const recurringOfferingPath = "userSubscriptionTypesForm.inputs.additionalRecurring";
			const recurringOfferings = get(state, recurringOfferingPath) as UserSubscriptionTypesFormInputsItem[];
			recurringOfferings.map(o => (o.isDefault.value = false));
			set(state, recurringOfferingPath, recurringOfferings);
			const recurringPath = "userSubscriptionTypesForm.inputs.recurring";
			const recurring = get(state, recurringPath) as UserSubscriptionTypesFormInputsItem;
			recurring.isDefault.value = false;
			set(state, recurringPath, recurring);
			const targetPath = `userSubscriptionTypesForm.inputs.${innerPath}.isDefault.value`;
			set(state, targetPath, value);
		},
		createInitialOffering: state => {
			const initialOfferingTemplatePath = PAYMENT_CONSTANTS.ADDITIONAL_INITIAL;
			const initialOfferingPath = `userSubscriptionTypesForm.inputs.${initialOfferingTemplatePath}`;
			const initialOfferings = get(state, initialOfferingPath) as UserSubscriptionTypesFormInputsItem[];
			const newInitialItem = parseTemplate(
				state.userSubscriptionTypesForm.templates[initialOfferingTemplatePath],
				`${initialOfferingPath}.${initialOfferings.length}`,
				{ defaultValueGetters }
			) as UserSubscriptionTypesFormInputsItem;
			initialOfferings!.push(newInitialItem);
			set(state, initialOfferingPath, initialOfferings);
		},
		createRecurringOffering: state => {
			const recurringOfferingTemplatePath = PAYMENT_CONSTANTS.ADDITIONAL_RECURRING;
			const recurringOfferingPath = `userSubscriptionTypesForm.inputs.${recurringOfferingTemplatePath}`;
			const recurringOfferings = get(state, recurringOfferingPath) as UserSubscriptionTypesFormInputsItem[];
			const newInitialItem = parseTemplate(
				state.userSubscriptionTypesForm.templates[recurringOfferingTemplatePath],
				`${recurringOfferingPath}.${recurringOfferings.length}`,
				{ defaultValueGetters }
			) as UserSubscriptionTypesFormInputsItem;
			recurringOfferings!.push(newInitialItem);
			set(state, recurringOfferingPath, recurringOfferings);
		},
		removeInitialOffering: (state, action: PayloadAction<{ index: number }>) => {
			const { index } = action.payload;
			const initialOfferingPath = `userSubscriptionTypesForm.inputs.additionalInitial.${index}`;
			set(state, `${initialOfferingPath}.stripeItem.deleted.value`, true);
		},
		removeRecurringOffering: (state, action: PayloadAction<{ index: number }>) => {
			const { index } = action.payload;
			const recurringOfferingPath = `userSubscriptionTypesForm.inputs.additionalRecurring.${index}`;
			set(state, `${recurringOfferingPath}.stripeItem.deleted.value`, true);
		},
		createAddonItem: (state, action: PayloadAction<string>) => {
			const addonTemplatePath = `${action.payload}.addons`;
			const addonItemsPath = `userSubscriptionTypesForm.inputs.${addonTemplatePath}`;
			const addons = get(state, addonItemsPath) as UserSubscriptionTypesFormAddonInputsItem[];
			const newAddonItem = parseTemplate(
				state.userSubscriptionTypesForm.templates[addonTemplatePath],
				`${addonItemsPath}.${addons.length}`,
				{ defaultValueGetters }
			) as UserSubscriptionTypesFormAddonInputsItem;
			addons!.push(newAddonItem);
			set(state, addonItemsPath, addons);
		},
		addCourseFeature: (state, action: PayloadAction<string>) => {
			const value = action.payload;
			value && state.data.data.marketingPoints.push(action.payload);
			state.courseFeaturesForm.inputs.feature.value = "";
		},
		deleteCourseFeature: (state, action: PayloadAction<string>) => {
			const value = action.payload;
			state.data.data.marketingPoints = state.data.data.marketingPoints.filter(
				marketingPoint => marketingPoint !== value
			);
		},
		updateCourseFeatures: (state, action: PayloadAction<{ id: number; feature: string }[]>) => {
			const arr = action.payload;
			const filteredArray = arr.filter(item => !item.id);
			state.data.data.marketingPoints = filteredArray as unknown as string[];
		},
		populateData: utilsPopulateData,
		populateInputs: utilsPopulateInputs,
		removeAddonItem: (state, action: PayloadAction<{ addonParent: string; addonIndex: number }>) => {
			const { addonParent, addonIndex } = action.payload;
			const addonItemsPath = `userSubscriptionTypesForm.inputs.${addonParent}.addons.${addonIndex}`;
			set(state, `${addonItemsPath}.shipStationItem.deleted.value`, true);
			set(state, `${addonItemsPath}.stripeItem.deleted.value`, true);
			const addonItemsRawDataPath = `userSubscriptionTypesForm.rawData.${addonParent}.addons.${addonIndex}`;
			set(state, `${addonItemsRawDataPath}.shipStationItem.deleted`, true);
			set(state, `${addonItemsRawDataPath}.stripeItem.deleted`, true);
		},
		resetState: utilsResetState,
		resetUserSubscriptionTypesForm: state => {
			const newInitialState = cloneDeep(initialState);
			state.userSubscriptionTypesForm = newInitialState.userSubscriptionTypesForm;
		},
		setStateValue: utilsSetStateValue,
		validateForm: utilsValidateFormAction
	}
});

export const {
	createInitialOffering,
	createRecurringOffering,
	removeInitialOffering,
	removeRecurringOffering,
	createAddonItem,
	addCourseFeature,
	deleteCourseFeature,
	updateCourseFeatures,
	populateData,
	populateInputs,
	removeAddonItem,
	resetState,
	resetUserSubscriptionTypesForm,
	setStateValue,
	setAsDefault,
	setAsDefaultRecurring,
	validateForm
} = courseAddEditSlice.actions;

export const fetchData = (id: number, options?: { form?: string; stateLoaderKey?: string }): AppThunk => {
	return async (dispatch, getState) => {
		const { form, stateLoaderKey = "isLoading" } = options || {};
		try {
			dispatch(resetUserSubscriptionTypesForm());
			const { courseAddEdit: state } = getState();
			dispatch(setStateValue({ key: stateLoaderKey, value: true }));
			const courseData = (await coursesService.findOne(id, {
				include: ["allowedForUST.subTypeEIDItems.children.counterparts", "allowedForUST.subTypeEIDItems.counterparts"],
				orderBy: { "allowedForUST.subTypeEIDItems.id": "asc" }
			}))!;
			let stateData = form ? { ...state.data } : {};
			if (!form || form === "courseForm") {
				const totalLength = courseData.calculateTimeLengthAutomatically
					? courseData.manualTimeLengthComponent
					: courseData.totalTimeLengthInMinutes;
				const courseFormInputValues = {
					...courseData,
					timeLengthHours: Math.floor(totalLength / 60),
					timeLengthMinutes: Math.floor(totalLength % 60)
				};
				stateData = { ...stateData, ...courseData };
				dispatch(
					setStateValue({
						key: "data",
						value: stateData
					})
				);
				dispatch(
					populateInputs({
						inputsStatePath: "courseForm.inputs",
						templatesStatePath: "courseForm.templates",
						values: courseFormInputValues as unknown as Record<string, unknown>
					})
				);
				if (courseFormInputValues.mainImageKey && courseFormInputValues.mainImageUrl) {
					dispatch(
						setStateValue({ key: "courseForm.inputs.mainImageKey.imageUrl", value: courseFormInputValues.mainImageUrl })
					);
				}
				dispatch(validateForm({ formStatePath: "courseForm" }));
			}
			if (!form || form === "userSubscriptionTypesForm") {
				const externalIntegrationData = { currency: "", planName: "" };
				const ustFormInputValues = cloneDeep(state.userSubscriptionTypesForm.rawData);
				const initialBase = cloneDeep(ustFormInputValues.additionalInitial[0]);
				const recurringBase = cloneDeep(ustFormInputValues.additionalRecurring[0]);
				ustFormInputValues.additionalInitial = [];
				ustFormInputValues.additionalRecurring = [];
				ustFormInputValues.initial.addons = [];
				ustFormInputValues.hasTrial = courseData.hasTrial as boolean;
				ustFormInputValues.hasQuestionBankOnlyConfiguration = false;
				ustFormInputValues.initial.isDigital = false;
				ustFormInputValues.initial.isDefault = false;
				let hasQuestionBankOnlyConfiguration = false;
				dispatch(
					setStateValue({
						key: "data",
						value: {
							...stateData,
							externalIntegrationData: {
								initial: { addons: [], isDigital: false, isDefault: false },
								recurring: {},
								recurringQuestionBankOnly: {},
								trial: {},
								additionalInitial: [],
								additionalRecurring: [],
								appleInitial: {},
								appleRecurring: {}
							}
						}
					})
				);
				let isFirstInitial = true;
				let isFirstRecurring = true;
				courseData
					.allowedForUST!.filter(ustItem => ustItem.isActive && !ustItem.isAppleSubscription)
					.sort((a, b) => {
						if (a.isRecurring === b.isRecurring) {
							return 0; // if both are the same, no sorting needed
						} else if (a.isRecurring === false) {
							return -1; // a comes before b
						} else {
							return 1; // b comes before a
						}
					})
					.forEach(ustItem => {
						const { duration, isRecurring, isTrial, userSubscriptionTypeCategoryId } = ustItem;
						if (!isTrial && !isRecurring) {
							hasQuestionBankOnlyConfiguration =
								hasQuestionBankOnlyConfiguration ||
								userSubscriptionTypeCategoryId === UserSubscriptionTypeCategoriesEnum.QuestionBank;
						}
						const mainStripeIntegrationItem =
							ustItem.subTypeEIDItems!.find(
								item => item.integrationId === ExternalIntegrationIds.Stripe && !item.parentId
							) || ({ data: {} } as UserSubscriptionTypeExternalIntegrationDataItem);

						const { children: dbAddons, counterparts: mainStripeItemCounterparts } = mainStripeIntegrationItem;
						const mainStripeIntegrationData = mainStripeIntegrationItem.data as StripeIntegrationData;
						const { planId: mainPlanId = "" } = mainStripeIntegrationData;
						const commonData: UserSubscriptionTypesFormRawDataItem = {
							...ustItem,
							deleted: false,
							shipStationItem: {
								id: 0,
								draftId: uuid(),
								name: "",
								productSKU: "",
								clientSKU: "",
								deleted: false
							},
							stripeItem: {
								id: mainStripeIntegrationItem.id,
								draftId: uuid(),
								counterpartIds: mainStripeItemCounterparts
									? mainStripeItemCounterparts.map(item => {
											return { id: item.id, draftId: "", deleted: false };
									  })
									: [],
								duration: "",
								planId: mainPlanId,
								price: 0,
								name: mainStripeIntegrationData.name!,
								deleted: false
							}
						} as UserSubscriptionTypesFormRawDataItem;
						let ustItemType = "";

						if (!externalIntegrationData.currency) {
							externalIntegrationData.currency = STRIPE_CURRENCY_SYMBOL_MAP[mainStripeIntegrationData.currency!];
						}
						if (!externalIntegrationData.planName) {
							externalIntegrationData.planName = mainStripeIntegrationData.planName!;
						}
						if (duration) {
							commonData.stripeItem.duration = getDurationStringFromDuration(duration);
						}
						if (isTrial) {
							ustItemType = "trial";
						} else if (
							isRecurring &&
							isFirstRecurring &&
							ustItem.userSubscriptionTypeCategoryId === UserSubscriptionTypeCategoriesEnum.Course
						) {
							isFirstRecurring = false;
							ustItemType = PAYMENT_CONSTANTS.RECURRING;

							ustFormInputValues.initial.nextSubscriptionTypeId = ustItem.id;
							ustFormInputValues.additionalInitial.forEach(item => (item.nextSubscriptionTypeId = ustItem.id));
							commonData.stripeItem.price = mainStripeIntegrationData.price!;
							ustFormInputValues.recurring.isDefault = ustItem.isDefault;
						} else if (
							isRecurring &&
							ustItem.userSubscriptionTypeCategoryId === UserSubscriptionTypeCategoriesEnum.QuestionBank
						) {
							ustItemType = PAYMENT_CONSTANTS.RECURRING_QUESTION_BANK_ONLY;
							commonData.stripeItem.price = mainStripeIntegrationData.price!;
						} else if (!isRecurring && isFirstInitial) {
							isFirstInitial = false;
							ustItemType = "initial";
							ustFormInputValues.trial.nextSubscriptionTypeId = ustItem.id;
							const initialShipStationIntegrationItem =
								mainStripeItemCounterparts &&
								mainStripeItemCounterparts.find(item => item.integrationId === ExternalIntegrationIds.PBS);
							const initialDigitalAssetIntegrationItem =
								mainStripeItemCounterparts &&
								mainStripeItemCounterparts.find(item => item.integrationId === ExternalIntegrationIds.DigitalAsset);
							ustFormInputValues.initial.isDigital = !!initialDigitalAssetIntegrationItem;
							ustFormInputValues.initial.isDefault = ustItem.isDefault;
							ustFormInputValues.initial.isQuestionBankOnly =
								ustItem.userSubscriptionTypeCategoryId === UserSubscriptionTypeCategoriesEnum.QuestionBank;
							const addons: UserSubscriptionTypesFormAddonInputsItemRawData[] = [];
							const dataAddons: {
								mainImageUrl?: string;
								mainImageKey?: string;
								stripePlanId: string;
								shipStationProductSKU?: string;
								digitalAssetProductKey?: string;
							}[] = [];
							commonData.stripeItem.price = mainStripeIntegrationData.price!;
							if (initialDigitalAssetIntegrationItem) {
								const digitalAssetData = initialDigitalAssetIntegrationItem.data as DigitalAssetIntegrationData;
								commonData.digitalAssetItem = {
									...commonData.digitalAssetItem,
									id: initialDigitalAssetIntegrationItem.id,
									name: digitalAssetData.name!,
									productKey: digitalAssetData.productKey
								};
								dispatch(
									setStateValue({
										key: "data.externalIntegrationData.initial.digitalAssetProductKey",
										value: digitalAssetData.productKey
									})
								);
								dispatch(
									setStateValue({
										key: "userSubscriptionTypesForm.inputs.initial.digitalAssetItem.productKey.name",
										value: digitalAssetData.name
									})
								);
								dispatch(toggleDigitalAsset(true, "initial"));
							}
							if (initialShipStationIntegrationItem) {
								const shipStationData = initialShipStationIntegrationItem.data as ShipStationIntegrationData;
								commonData.shipStationItem = {
									...commonData.shipStationItem,
									id: initialShipStationIntegrationItem.id,
									name: shipStationData.name!,
									productSKU: shipStationData.productSKU,
									clientSKU: shipStationData.clientSKU
								};
								dispatch(
									setStateValue({
										key: "data.externalIntegrationData.initial.shipStationProductSKU",
										value: shipStationData.productSKU
									})
								);
								dispatch(toggleDigitalAsset(false, "initial"));
							}
							if (dbAddons instanceof Array && dbAddons.length) {
								dbAddons.forEach(addonItem => {
									if (addonItem.integrationId !== ExternalIntegrationIds.Stripe) {
										return;
									}
									const { counterparts } = addonItem;
									const addonStripeData = addonItem.data as StripeIntegrationData;
									let addonIsDigital = false;
									const counterpartIds: BaseSubItemFormInputsRawData[] = [];
									let addonShipStationItem = {
										deleted: false,
										draftId: uuid(),
										id: 0,
										name: "",
										productSKU: "",
										clientSKU: ""
									};
									//TODO:UPDATE FETCH FOR DIGITAL ASSETS
									let addonDigitalAssetItem = {
										deleted: false,
										draftId: uuid(),
										id: 0,
										name: "",
										productKey: ""
									};
									if (counterparts && counterparts.length) {
										const shipStationIntegrationItem = counterparts.find(
											item => item.integrationId === ExternalIntegrationIds.PBS
										);
										const digitalAssetIntegrationItem = counterparts.find(
											item => item.integrationId === ExternalIntegrationIds.DigitalAsset
										);
										addonIsDigital = !!digitalAssetIntegrationItem;
										if (shipStationIntegrationItem) {
											const addonShipStationData = shipStationIntegrationItem.data as ShipStationIntegrationData;
											addonShipStationItem = {
												...addonShipStationItem,
												id: shipStationIntegrationItem.id,
												name: addonShipStationData.name!,
												productSKU: addonShipStationData.productSKU,
												clientSKU: addonShipStationData.clientSKU
											};
										}
										if (digitalAssetIntegrationItem) {
											const addonShipStationData = digitalAssetIntegrationItem.data as DigitalAssetIntegrationData;
											addonDigitalAssetItem = {
												...addonDigitalAssetItem,
												id: digitalAssetIntegrationItem.id,
												name: addonShipStationData.name!,
												productKey: addonShipStationData.productKey
											};
										}
									}
									if (!addonIsDigital && !addonShipStationItem.id) {
										counterpartIds.push({ id: 0, draftId: addonShipStationItem.draftId, deleted: false });
									}
									if (addonIsDigital && !addonDigitalAssetItem.id) {
										counterpartIds.push({ id: 0, draftId: addonDigitalAssetItem.draftId, deleted: false });
									}
									addons.push({
										shipStationItem: addonShipStationItem,
										digitalAssetItem: addonDigitalAssetItem,
										isDigital: addonIsDigital,
										stripeItem: {
											counterpartIds: counterpartIds.concat(
												addonItem.counterparts
													? addonItem.counterparts.map(cpItem => {
															return { id: cpItem.id, draftId: "", deleted: false };
													  })
													: []
											),
											deleted: false,
											draftId: "",
											duration: "",
											id: addonItem.id,
											planId: addonStripeData.planId,
											price: addonStripeData.price,
											name: addonStripeData.name!
										},
										mainImageKey: addonItem?.mainImageKey as string,
										mainImageUrl: addonItem?.mainImageUrl as string,
										isDefault: addonStripeData?.isDefault as boolean
									});
									dataAddons.push({
										stripePlanId: addonStripeData.planId,
										digitalAssetProductKey: addonDigitalAssetItem.productKey,
										shipStationProductSKU: addonShipStationItem.productSKU,
										mainImageKey: addonItem?.mainImageKey as string,
										mainImageUrl: addonItem?.mainImageUrl as string
									});
								});
							}
							dispatch(setStateValue({ key: "data.externalIntegrationData.initial.addons", value: dataAddons }));
							ustFormInputValues.initial.addons = addons;
						} else {
							ustItemType = ustItem.isRecurring
								? PAYMENT_CONSTANTS.ADDITIONAL_RECURRING
								: PAYMENT_CONSTANTS.ADDITIONAL_INITIAL;
							commonData.stripeItem.price = mainStripeIntegrationData.price!;
							commonData.isDefault = ustItem.isDefault;
							commonData.isQuestionBankOnly =
								ustItem.userSubscriptionTypeCategoryId === UserSubscriptionTypeCategoriesEnum.QuestionBank;
						}
						if (
							ustItemType == PAYMENT_CONSTANTS.ADDITIONAL_INITIAL ||
							ustItemType == PAYMENT_CONSTANTS.ADDITIONAL_RECURRING
						) {
							let innerPath = `${ustItemType}.${ustFormInputValues.additionalInitial.length}`;
							if (ustItemType === PAYMENT_CONSTANTS.ADDITIONAL_RECURRING) {
								innerPath = `${ustItemType}.${ustFormInputValues.additionalRecurring.length}`;
							}
							let newItem = {
								...initialBase,
								...commonData
							};
							if (ustItemType === PAYMENT_CONSTANTS.ADDITIONAL_RECURRING) {
								newItem = {
									...recurringBase,
									...commonData
								};
								ustFormInputValues.additionalRecurring.push(newItem);
							} else {
								ustFormInputValues.additionalInitial.push(newItem);
							}
							if (mainPlanId) {
								dispatch(
									setStateValue({
										key: `data.externalIntegrationData.${innerPath}.stripePlanId`,
										value: mainPlanId
									})
								);
								dispatch(
									setStateValue({
										key: `data.externalIntegrationData.${innerPath}.id`,
										value: newItem.id
									})
								);
								dispatch(
									setStateValue({
										key: `data.externalIntegrationData.${innerPath}.isDefault`,
										value: newItem.isDefault
									})
								);
								dispatch(fetchStripeData(mainPlanId, innerPath));
							}
						} else {
							ustFormInputValues[ustItemType] = {
								...ustFormInputValues[ustItemType],
								...commonData
							};
							if (mainPlanId) {
								dispatch(
									setStateValue({
										key: `data.externalIntegrationData.${ustItemType}.stripePlanId`,
										value: mainPlanId
									})
								);
								dispatch(
									setStateValue({
										key: `data.externalIntegrationData.${ustItemType}.isDefault`,
										value: ustFormInputValues[ustItemType].isDefault
									})
								);
								dispatch(fetchStripeData(mainPlanId, ustItemType));
							}
						}
					});
				courseData
					.allowedForUST!.filter(ustItem => ustItem.isActive && ustItem.isAppleSubscription)
					.forEach(ustItem => {
						const { isRecurring } = ustItem;
						const mainAppleIntegrationItem = ustItem.subTypeEIDItems!.find(
							item => item.integrationId === 5
						) as UserSubscriptionTypeExternalIntegrationDataItem;
						const mainAppleIntegrationData = mainAppleIntegrationItem.data as AppleIntegrationData;
						const productKey = mainAppleIntegrationData.productKey;

						ustFormInputValues.initial.appleProductItem = {
							...ustFormInputValues.initial.appleProductItem,
							productKey: productKey!
						};

						if (isRecurring) {
							ustFormInputValues.appleRecurring = {
								...ustFormInputValues.appleRecurring,
								...ustItem
							};
						} else {
							ustFormInputValues.appleInitial = {
								...ustFormInputValues.appleInitial,
								...ustItem
							};
							ustFormInputValues.appleInitial.appleProductItem = {
								...ustFormInputValues.appleInitial.appleProductItem,
								name: mainAppleIntegrationData.name!,
								productKey: productKey!
							};

							dispatch(
								setStateValue({
									key: "data.externalIntegrationData.appleProductSKU",
									value: productKey
								})
							);
							if (productKey) {
								dispatch(fetchAppleProductData(productKey, "initial"));
							}
						}
					});
				ustFormInputValues.hasQuestionBankOnlyConfiguration = hasQuestionBankOnlyConfiguration;
				dispatch(
					setStateValue({
						key: "userSubscriptionTypesForm.inputs.recurringQuestionBankOnly.stripeItem.planId.required",
						value: hasQuestionBankOnlyConfiguration
					})
				);
				// Set next type for recurring and initials
				const setNextTypeId = (item: UserSubscriptionTypesFormRawDataItem): void => {
					let targetItem = {} as UserSubscriptionTypesFormRawDataItem;
					if (item.isQuestionBankOnly) {
						targetItem = ustFormInputValues.recurringQuestionBankOnly;
					} else {
						targetItem =
							ustFormInputValues.recurring.isDefault ||
							ustFormInputValues.additionalRecurring.some(f => !f.isDefault) ||
							!ustFormInputValues.additionalRecurring.length
								? ustFormInputValues.recurring
								: ustFormInputValues.additionalRecurring.find(f => f.isDefault)!;
					}
					if (targetItem.id) {
						item.nextSubscriptionTypeId = targetItem.id;
					} else {
						item.nextSubscriptionTypeDraftId = targetItem.draftId;
					}
				};
				ustFormInputValues.additionalInitial.forEach(item => {
					setNextTypeId(item);
				});
				ustFormInputValues.additionalRecurring.forEach(item => {
					setNextTypeId(item);
				});
				dispatch(
					populateInputs({
						inputsStatePath: "userSubscriptionTypesForm.inputs",
						templatesStatePath: "userSubscriptionTypesForm.templates",
						values: ustFormInputValues as unknown as Record<string, unknown>
					})
				);
				for (const index in ustFormInputValues.initial.addons) {
					const addon = ustFormInputValues.initial.addons[index];
					const path = `initial.addons.${index}`;
					if (addon.isDigital) {
						dispatch(
							setStateValue({
								key: `userSubscriptionTypesForm.inputs.${path}.digitalAssetItem.productKey.name`,
								value: addon.digitalAssetItem.name
							})
						);
						dispatch(toggleDigitalAsset(true, path));
					}
				}
				dispatch(setStateValue({ key: "externalIntegrationData", value: externalIntegrationData }));
				dispatch(validateForm({ formStatePath: "userSubscriptionTypesForm" }));
			}
			dispatch(setStateValue({ key: "newCourseId", value: undefined }));
			if (!form || form === "courseFeaturesForm") {
				const courseFeaturesFormInputValues = {
					additionalInfo: courseData?.data?.additionalInformation
				};
				dispatch(
					populateInputs({
						inputsStatePath: "courseFeaturesForm.inputs",
						values: courseFeaturesFormInputValues as unknown as Record<string, unknown>
					})
				);
			}
		} catch (e) {
			console.error(e);
			dispatch(emit({ message: "Error fetching the course data.", color: "error", preventAutoDismiss: true }));
		} finally {
			dispatch(setStateValue({ key: stateLoaderKey, value: false }));
		}
	};
};

export const toggleDigitalAsset = (isDigitalRequired: boolean, innerPath: string): AppThunk => {
	return async dispatch => {
		const digitalAssetPath = `userSubscriptionTypesForm.inputs.${innerPath}.digitalAssetItem`;
		const shipStationPath = `userSubscriptionTypesForm.inputs.${innerPath}.shipStationItem`;
		dispatch(
			setStateValue({
				key: `${digitalAssetPath}.productKey.validations.required`,
				value: isDigitalRequired
			})
		);
		dispatch(setStateValue({ key: `${shipStationPath}.productSKU.validations.required`, value: !isDigitalRequired }));
		dispatch(setStateValue({ key: `${shipStationPath}.clientSKU.validations.required`, value: !isDigitalRequired }));
	};
};

export const toggleIsQuestionBankOnly = (): AppThunk => {
	return async (dispatch, getState) => {
		const { courseAddEdit: state } = getState();
		//Get value of additionalInitial + initial
		const initialPath = "userSubscriptionTypesForm.inputs.initial.isQuestionBankOnly";
		const additionalInitialPath = "userSubscriptionTypesForm.inputs.additionalInitial";
		const { value: initialIsQuestionBankOnly } = get(state, initialPath);
		const additionalInitial = get(state, additionalInitialPath);
		const hasQuestionBankOnlyAccess = additionalInitial.reduce(
			(acc, item) => acc || item.isQuestionBankOnly.value,
			initialIsQuestionBankOnly
		);
		//Calculate hasQuestionBankOnlyAccess
		dispatch(
			setStateValue({
				key: "userSubscriptionTypesForm.inputs.hasQuestionBankOnlyConfiguration.value",
				value: hasQuestionBankOnlyAccess
			})
		);
		dispatch(
			setStateValue({
				key: "userSubscriptionTypesForm.inputs.recurringQuestionBankOnly.stripeItem.planId.required",
				value: hasQuestionBankOnlyAccess
			})
		);
	};
};

export const setDigitalAssetName = (file: File, innerPath: string): AppThunk => {
	return async dispatch => {
		const digitalAssetPath = `userSubscriptionTypesForm.inputs.${innerPath}.digitalAssetItem`;
		dispatch(
			setStateValue({
				key: `${digitalAssetPath}.name.value`,
				value: file.name
			})
		);
	};
};

export const fetchAppleProductData = (productKey: string, innerPath: string): AppThunk => {
	return async (dispatch, getState) => {
		if (!productKey) {
			return;
		}
		const basePath = `userSubscriptionTypesForm.inputs.${innerPath}.appleProductItem`;
		try {
			await new Promise<void>(resolve => {
				setTimeout(() => resolve(), 500);
			});
			const { courseAddEdit: state } = getState();

			const { value: inputValue } = get(state, `${basePath}.productKey`);
			if (inputValue !== productKey) {
				return;
			}
			dispatch(setStateValue({ key: `${basePath}.name.value`, value: "" }));
			dispatch(setStateValue({ key: `${basePath}.isLoading.value`, value: true }));
			const appleProductItem = await userSubscriptionTypesService.getAppleProductData(productKey);
			dispatch(setStateValue({ key: `${basePath}.name.value`, value: appleProductItem.name }));
			dispatch(setStateValue({ key: `${basePath}.duration.value`, value: appleProductItem.duration }));
		} catch (e) {
			const err = e as { message: string };
			if (err && err.message) {
				dispatch(setStateValue({ key: `${basePath}.name.error`, value: "Unable to find apple product" }));
				dispatch(setStateValue({ key: "userSubscriptionTypesForm.valid", value: false }));
				dispatch(_emit(err.message, "error"));
				return;
			}
			dispatch(_emit("An error has occurred while retrieving data from Apple side.", "error"));
		} finally {
			dispatch(setStateValue({ key: `${basePath}.isLoading.value`, value: false }));
		}
	};
};

export const fetchShipStationData = (productSKU: string, innerPath: string): AppThunk => {
	return async (dispatch, getState) => {
		if (!productSKU) {
			return;
		}
		const basePath = `userSubscriptionTypesForm.inputs.${innerPath}.shipStationItem`;
		try {
			await new Promise<void>(resolve => {
				setTimeout(() => resolve(), 500);
			});
			const { courseAddEdit: state } = getState();
			const { value: inputValue } = get(state, `${basePath}.clientSKU`);
			if (inputValue !== productSKU) {
				return;
			}
			dispatch(setStateValue({ key: `${basePath}.name.value`, value: "" }));
			dispatch(setStateValue({ key: `${basePath}.isLoading.value`, value: true }));
			const shipStationData = await userSubscriptionTypesService.getStockKeepingProductData(
				ExternalIntegrationIds.PBS,
				productSKU
			);
			dispatch(setStateValue({ key: `${basePath}.name.value`, value: shipStationData.name }));
			dispatch(setStateValue({ key: `${basePath}.productSKU.value`, value: shipStationData.productSKU }));
		} catch (e) {
			const err = e as { message: string };
			if (err && err.message) {
				dispatch(
					setStateValue({ key: `${basePath}.productSKU.error`, value: "Unable to find the Acutrack Product SKU Id" })
				);
				dispatch(setStateValue({ key: "userSubscriptionTypesForm.valid", value: false }));
				dispatch(_emit(err.message, "error"));
				return;
			}
			dispatch(_emit("An error has occurred while retrieving data from Acutrack.", "error"));
		} finally {
			dispatch(setStateValue({ key: `${basePath}.isLoading.value`, value: false }));
		}
	};
};

export const fetchStripeData = (planId: string, innerPath: string): AppThunk => {
	return async (dispatch, getState) => {
		if (!planId) {
			return;
		}
		const basePath = `userSubscriptionTypesForm.inputs.${innerPath}.stripeItem`;
		try {
			await new Promise<void>(resolve => {
				setTimeout(() => resolve(), 500);
			});
			const { courseAddEdit: state } = getState();
			const { externalIntegrationData } = state;
			const { value: inputValue } = get(state, `${basePath}.planId`);
			if (inputValue !== planId) {
				return;
			}
			const isAddonItem = innerPath.match(/addons/);
			dispatch(setStateValue({ key: `${basePath}.isLoading.value`, value: true }));
			///Reset the values before get call
			dispatch(setStateValue({ key: `${basePath}.name.value`, value: "" }));
			dispatch(setStateValue({ key: `${basePath}.price.value`, value: 0 }));
			dispatch(
				setStateValue({
					key: `${basePath}.duration.value`,
					value: undefined
				})
			);
			const stripeAPIData = await userSubscriptionTypesService.getPaymentProviderPriceData(
				ExternalIntegrationIds.Stripe,
				planId
			);
			if (PAYMENT_CONSTANTS.RECURRING === innerPath && stripeAPIData.type === PAYMENT_CONSTANTS.ONE_TIME) {
				dispatch(_emit("Invalid entry for recurring subscription", "error"));
				return;
			}
			if (
				(PAYMENT_CONSTANTS.INITIAL === innerPath || innerPath.includes(PAYMENT_CONSTANTS.ADDITIONAL_INITIAL)) &&
				stripeAPIData.type === PAYMENT_CONSTANTS.ONE_TIME
			) {
				dispatch(_emit("Invalid entry for initial subscription", "error"));
				return;
			}
			if (isAddonItem && stripeAPIData.type !== PAYMENT_CONSTANTS.ONE_TIME) {
				dispatch(_emit("Addons should be one time payment", "error"));
				return;
			}
			dispatch(setStateValue({ key: `${basePath}.name.value`, value: stripeAPIData.name }));
			dispatch(setStateValue({ key: `${basePath}.price.value`, value: stripeAPIData.price }));
			if (stripeAPIData.duration) {
				dispatch(
					setStateValue({
						key: `${basePath}.duration.value`,
						value: stripeAPIData.duration
					})
				);
			}
			if (!isAddonItem) {
				if (!externalIntegrationData.currency || !externalIntegrationData.currency.length) {
					dispatch(
						setStateValue({
							key: "externalIntegrationData.currency",
							value: STRIPE_CURRENCY_SYMBOL_MAP[stripeAPIData.currency]
						})
					);
				}
				if (!externalIntegrationData.planName || !externalIntegrationData.planName.length) {
					dispatch(setStateValue({ key: "externalIntegrationData.planName", value: stripeAPIData.planName }));
				}
			}
			dispatch(setStateValue({ key: `${basePath}.isLoading.value`, value: false }));
		} catch (e) {
			const err = e as { message: string };
			if (err && err.message) {
				dispatch(_emit(err.message, "error"));
				return;
			}
			dispatch(_emit("An error has occurred while retrieving data from Stripe.", "error"));
		} finally {
			dispatch(setStateValue({ key: `${basePath}.isLoading.value`, value: false }));
		}
	};
};

export function getFullState(state: RootState): CourseAddEditState {
	return state.courseAddEdit;
}

export function getStateValue<ReturnType>(state: RootState, key: string): ReturnType {
	return utilsGetStateValue<CourseAddEditState, ReturnType>(state.courseAddEdit, key);
}

export const saveForm = (form?: string): AppThunk => {
	return async (dispatch, getState) => {
		try {
			dispatch(setStateValue({ key: "saveIsLoading", value: true }));
			const { courseAddEdit: state } = getState();
			const { data: stateCourseData, externalIntegrationData } = state;
			let body: CourseCreateDto | CourseUpdateDataDto | null = null;
			let courseData = { ...stateCourseData };
			if (!form || form === "courseForm") {
				dispatch(validateForm({ formStatePath: "courseForm", markInputsAsDirty: true }));
				const { courseForm } = getState().courseAddEdit;
				if (!courseForm.valid) {
					dispatch(_emit("Please fill in all fields correctly and try again.", "error"));
					return;
				}
				const { rawData: courseFormData } = courseForm;
				const totalLength =
					parseInt(courseFormData.timeLengthHours as unknown as string, 10) * 60 +
					parseInt(courseFormData.timeLengthMinutes as unknown as string, 10);
				body = {
					...courseFormData,
					endOfCourseSummary: courseFormData.name,
					manualTimeLengthComponent: courseData.manualTimeLengthComponent,
					totalTimeLengthInMinutes: courseData.totalTimeLengthInMinutes,
					userSubscriptionTypes: [],
					allowedForUserTypeIds: [{ value: UserTypes.RegularStudent }],
					freeShipping: courseFormData.freeShipping
				};
				courseData = { ...courseData, ...courseFormData };
				if (courseFormData.mainImageKey) {
					body.mainImageKey = courseFormData.mainImageKey;
				}
				if (courseFormData.calculateTimeLengthAutomatically) {
					body.manualTimeLengthComponent = totalLength;
				} else {
					body.totalTimeLengthInMinutes = totalLength;
				}
			} else {
				body = {
					name: courseData.name,
					endOfCourseSummary: courseData.name,
					isActive: courseData.isActive,
					calculateTimeLengthAutomatically: courseData.calculateTimeLengthAutomatically,
					manualTimeLengthComponent: courseData.manualTimeLengthComponent,
					totalTimeLengthInMinutes: courseData.totalTimeLengthInMinutes,
					userSubscriptionTypes: [],
					allowedForUserTypeIds: [{ value: UserTypes.RegularStudent }]
				};
			}
			if (!form || form === "userSubscriptionTypesForm") {
				dispatch(validateForm({ formStatePath: "userSubscriptionTypesForm", markInputsAsDirty: true }));
				const { userSubscriptionTypesForm } = getState().courseAddEdit;
				if (!userSubscriptionTypesForm.valid) {
					dispatch(_emit("Please fill in all fields correctly and try again.", "error"));
					return;
				}
				const {
					initial: originalInitialExternalIntegrationData,
					recurring: originalRecurringExternalIntegrationData,
					recurringQuestionBankOnly: originalRecurringQuestionBankOnlyExternalIntegrationData,
					additionalInitial: originalAdditonalInitialExternalIntegrationData,
					additionalRecurring: originalAdditonalRecurringExternalIntegrationData
				} = stateCourseData.externalIntegrationData;

				if (userSubscriptionTypesForm.valid) {
					const {
						initial,
						recurring,
						recurringQuestionBankOnly,
						trial,
						hasTrial,
						additionalInitial,
						additionalRecurring,
						hasQuestionBankOnlyConfiguration,
						appleInitial,
						appleRecurring
					} = cloneDeep(userSubscriptionTypesForm.rawData);
					const { appleProductItem: originalAppleProductItem } = appleInitial;
					body.hasTrial = hasTrial;
					const {
						shipStationItem: initialShipStationItem,
						digitalAssetItem: initialDigitalAssetItem,
						isDigital: initialIsDigital,
						stripeItem: initialStripeItem,
						isQuestionBankOnly: initialIsQuestionBankOnly,
						appleProductItem: initialAppleProductItem
					} = initial;
					let isDefaultSelected = initial.isDefault;
					let isDefaultRecurringSelected = recurring.isDefault;
					const { stripeItem: recurringStripeItem } = recurring;
					const { stripeItem: recurringQuestionBankOnlyStripeItem } = recurringQuestionBankOnly;
					const addons: UserSubscriptionTypeExternalIntegrationDataItemUpsertDto[] = [];
					const assetTypeChanged = initialIsDigital !== originalInitialExternalIntegrationData.isDigital;
					const shipStationSkuUpdated =
						(assetTypeChanged && !originalInitialExternalIntegrationData.shipStationProductSKU) ||
						initialShipStationItem.productSKU !== originalInitialExternalIntegrationData.shipStationProductSKU;
					const digitalAssetKeyUpdated =
						(assetTypeChanged && !originalInitialExternalIntegrationData.digitalAssetProductKey) ||
						initialDigitalAssetItem.productKey !== originalInitialExternalIntegrationData.digitalAssetProductKey;
					const accessChanged = initialIsQuestionBankOnly !== originalInitialExternalIntegrationData.isQuestionBankOnly;
					const addRecurring =
						!originalRecurringExternalIntegrationData.stripePlanId ||
						recurringStripeItem.planId !== originalRecurringExternalIntegrationData.stripePlanId ||
						digitalAssetKeyUpdated ||
						originalRecurringExternalIntegrationData.isDefault !== recurring.isDefault;
					const addRecurringQuestionBankOnly =
						(hasQuestionBankOnlyConfiguration &&
							!originalRecurringQuestionBankOnlyExternalIntegrationData.stripePlanId) ||
						recurringQuestionBankOnlyStripeItem.planId !==
							originalRecurringQuestionBankOnlyExternalIntegrationData.stripePlanId;
					const appleProductKeyChanged = initialAppleProductItem.productKey !== originalAppleProductItem.productKey;
					let addTrial = false;
					const { price } = initialStripeItem;
					const { price: recuringPrice } = recurringStripeItem;
					const { price: recurringQuestionBankOnlyPrice } = recurringQuestionBankOnlyStripeItem;

					if (!price) {
						dispatch(_emit("Intial price should not be zero", "error"));
						return;
					}
					if (!recuringPrice) {
						dispatch(_emit("Recurring charge should not be zero", "error"));
						return;
					}
					if (hasQuestionBankOnlyConfiguration && !recurringQuestionBankOnlyPrice) {
						dispatch(_emit("Recurring (Question Bank Only) charge should not be zero", "error"));
						return;
					}
					if (!initialIsDigital && !initialShipStationItem.name) {
						dispatch(_emit("Initial Acutrack Product Name should not be empty", "error"));
						return;
					}
					if (initialIsDigital && !initialDigitalAssetItem.productKey) {
						dispatch(_emit("Initial Digital Item should not be empty", "error"));
						return;
					}
					if (initial.isQuestionBankOnly) {
						if (addRecurringQuestionBankOnly) {
							initial.nextSubscriptionTypeDraftId = recurringQuestionBankOnly.draftId;
						} else {
							initial.nextSubscriptionTypeId = recurringQuestionBankOnly.id;
						}
					} else {
						if (addRecurring) {
							initial.nextSubscriptionTypeDraftId = recurring.draftId;
						} else {
							initial.nextSubscriptionTypeId = recurring.id;
						}
					}
					initial.name = `Course ${courseData.name} - ${externalIntegrationData.planName}`;
					initial.description = initial.name;
					recurring.name = `Course ${courseData.name} - ${externalIntegrationData.planName} (Recurring)`;
					recurring.description = recurring.name;
					recurringQuestionBankOnly.name = `Course ${courseData.name} - ${externalIntegrationData.planName} (Recurring - Question Bank)`;
					recurringQuestionBankOnly.description = recurringQuestionBankOnly.name;
					appleInitial.name = `Course ${courseData.name} - ${initialAppleProductItem.name} (Initial - Apple Product)`;
					appleInitial.description = appleInitial.name;
					appleRecurring.name = `Course ${courseData.name} - ${initialAppleProductItem.name} (Recurring - Apple Product)`;
					appleRecurring.description = appleRecurring.name;
					trial.name = `Course ${courseData.name} - ${externalIntegrationData.planName} (Trial)`;
					trial.description = trial.name;
					let initialOfferingCount = 1;
					let recurringOfferingCount = 1;
					let updateAdditionalOffering = false;
					let updateAdditionalRecurringOffering = false;
					additionalInitial.forEach(item => {
						if (!item.stripeItem.deleted) {
							isDefaultSelected = isDefaultSelected || item.isDefault;
							initialOfferingCount += 1;
							if (item.id) {
								const originalItem = originalAdditonalInitialExternalIntegrationData.find(i => i.id == item.id)!;
								const updated =
									originalItem.stripePlanId !== item.stripeItem.planId ||
									originalItem.isDefault != item.isDefault ||
									originalItem.isQuestionBankOnly != item.isQuestionBankOnly;
								updateAdditionalOffering = updateAdditionalOffering || updated;
							} else {
								updateAdditionalOffering = updateAdditionalOffering || true;
							}
						} else {
							if (item.id) {
								updateAdditionalOffering = updateAdditionalOffering || true;
							}
						}
						if (item.isQuestionBankOnly) {
							if (addRecurringQuestionBankOnly) {
								item.nextSubscriptionTypeDraftId = recurringQuestionBankOnly.draftId;
							} else {
								item.nextSubscriptionTypeId = recurringQuestionBankOnly.id;
							}
						} else {
							if (addRecurring) {
								item.nextSubscriptionTypeDraftId = recurring.draftId;
							} else {
								item.nextSubscriptionTypeId = recurring.id;
							}
						}
						item.name = `Course ${courseData.name} - ${externalIntegrationData.planName} (${initialOfferingCount} Offering)`;
						item.description = item.name;
					});
					additionalRecurring.forEach(item => {
						if (!item.stripeItem.deleted) {
							isDefaultRecurringSelected = isDefaultRecurringSelected || item.isDefault;
							recurringOfferingCount += 1;
							if (item.id) {
								const originalItem = originalAdditonalRecurringExternalIntegrationData.find(i => i.id == item.id)!;
								const updated =
									originalItem.stripePlanId !== item.stripeItem.planId || originalItem.isDefault != item.isDefault;
								updateAdditionalRecurringOffering = updateAdditionalRecurringOffering || updated;
							} else {
								updateAdditionalRecurringOffering = updateAdditionalRecurringOffering || true;
							}
						} else {
							if (item.id) {
								updateAdditionalRecurringOffering = updateAdditionalRecurringOffering || true;
							}
						}
						if (addRecurring) {
							item.nextSubscriptionTypeDraftId = recurring.draftId;
						} else {
							item.nextSubscriptionTypeId = recurring.id;
						}
						item.name = `Course ${courseData.name} - ${externalIntegrationData.planName} (${recurringOfferingCount} Recurring Offering)`;
						item.description = item.name;
					});
					if (!isDefaultRecurringSelected) {
						dispatch(_emit("Select Most Popular Recurring Offering", "error"));
						return;
					}
					if (!isDefaultSelected) {
						dispatch(_emit("Select Most Popular Initial Offering", "error"));
						return;
					}
					if (initial.addons) {
						for (let index = 0; index < initial.addons.length; index++) {
							const addonItem = initial.addons[index];
							const {
								shipStationItem: addonShipStationItem,
								digitalAssetItem: addonDigitalAssetItem,
								isDigital: addonIsDigital,
								isDefault: addonIsDefault,
								stripeItem: addonStripeItem,
								mainImageKey
							} = addonItem;
							const { price: addonPrice, deleted } = addonStripeItem;
							const { deleted: addonShipStationDeleted } = addonShipStationItem;
							if (!addonPrice && deleted === false) {
								dispatch(_emit("Addons price should not be zero", "error"));
								return;
							}
							if (!addonIsDigital && !addonShipStationItem.name) {
								dispatch(_emit("Addons Acutrack Product Name should not be empty", "error"));
								return;
							}
							if (addonIsDigital && !addonDigitalAssetItem.productKey) {
								dispatch(_emit("Addons Digital Asset should not be empty", "error"));
								return;
							}
							if (!mainImageKey && addonShipStationDeleted === false) {
								dispatch(_emit("Addons image is required", "error"));
								return;
							}
							const digitalAssetAddonItem: UserSubscriptionTypeExternalIntegrationDataItemUpsertDto = {
								draftId: addonDigitalAssetItem.draftId,
								integrationId: ExternalIntegrationIds.DigitalAsset,
								subscriptionTypeId: 0, // this gets overwritten in the back-end
								data: {
									name: addonDigitalAssetItem.name,
									productKey: addonDigitalAssetItem.productKey
								},
								deleted: addonDigitalAssetItem.deleted
							};
							const shipStationApiAddonItem: UserSubscriptionTypeExternalIntegrationDataItemUpsertDto = {
								draftId: addonShipStationItem.draftId,
								integrationId: ExternalIntegrationIds.PBS,
								subscriptionTypeId: 0, // this gets overwritten in the back-end
								data: {
									name: addonShipStationItem.name,
									productSKU: addonShipStationItem.productSKU,
									clientSKU: addonShipStationItem.clientSKU
								},
								deleted: addonShipStationItem.deleted
							};
							const stripeApiAddonItem: UserSubscriptionTypeExternalIntegrationDataItemUpsertDto = {
								draftId: addonStripeItem.draftId,
								integrationId: ExternalIntegrationIds.Stripe,
								subscriptionTypeId: 0, // this gets overwritten in the back-end
								data: { planId: addonStripeItem.planId, isDefault: addonIsDefault },
								counterpartIds: [],
								counterpartDraftIds: [],
								deleted: addonStripeItem.deleted,
								mainImageKey: mainImageKey
							};
							digitalAssetAddonItem.parentDraftId = initialStripeItem.draftId;
							shipStationApiAddonItem.parentDraftId = initialStripeItem.draftId;
							stripeApiAddonItem.parentDraftId = initialStripeItem.draftId;
							if (!addonIsDigital) {
								digitalAssetAddonItem.deleted = true;
								stripeApiAddonItem.counterpartDraftIds!.push(addonShipStationItem.draftId);
							} else {
								shipStationApiAddonItem.deleted = true;
								stripeApiAddonItem.counterpartDraftIds!.push(addonDigitalAssetItem.draftId);
							}

							const { planId } = addonStripeItem;
							if (!planId && !planId?.length) {
								digitalAssetAddonItem.deleted = true;
								shipStationApiAddonItem.deleted = true;
								stripeApiAddonItem.deleted = true;
							}
							addons.push(digitalAssetAddonItem);
							addons.push(shipStationApiAddonItem);
							addons.push(stripeApiAddonItem);
						}
					}
					const addonsUpdate =
						initial.addons?.filter(f => f.stripeItem.deleted === false).length !==
							originalInitialExternalIntegrationData.addons?.length ||
						!initial.addons?.every(ia =>
							originalInitialExternalIntegrationData.addons?.some(
								oa =>
									oa.mainImageKey == ia.mainImageKey &&
									(oa.shipStationProductSKU == ia.shipStationItem.productSKU ||
										oa.digitalAssetProductKey == ia.digitalAssetItem.productKey) &&
									oa.isDigital == ia.isDigital &&
									oa.stripePlanId === ia.stripeItem.planId
							)
						);
					const addInitial =
						accessChanged ||
						digitalAssetKeyUpdated ||
						shipStationSkuUpdated ||
						!originalInitialExternalIntegrationData.stripePlanId ||
						initialStripeItem.planId !== originalInitialExternalIntegrationData.stripePlanId ||
						initial.isDefault !== originalInitialExternalIntegrationData.isDefault ||
						appleProductKeyChanged ||
						addonsUpdate;
					updateAdditionalOffering = updateAdditionalOffering || addInitial;
					updateAdditionalRecurringOffering =
						updateAdditionalRecurringOffering || digitalAssetKeyUpdated || addRecurring;
					body.userSubscriptionTypes = [];
					if (addInitial) {
						//We are replacing subscription with new one so we add draft id
						trial.nextSubscriptionTypeDraftId = initial.draftId;
					} else {
						// We have an existing initial subscription so we use id
						trial.nextSubscriptionTypeId = initial.id;
					}
					const bookItem = !initialIsDigital
						? {
								id: 0,
								draftId: initialShipStationItem.draftId,
								integrationId: ExternalIntegrationIds.PBS,
								subscriptionTypeId: 0,
								data: {
									name: initialShipStationItem.name,
									productSKU: initialShipStationItem.productSKU,
									clientSKU: initialShipStationItem.clientSKU
								},
								deleted: initialShipStationItem.deleted
						  }
						: {
								id: 0,
								draftId: initialDigitalAssetItem.draftId,
								integrationId: ExternalIntegrationIds.DigitalAsset,
								subscriptionTypeId: 0,
								data: {
									name: initialDigitalAssetItem.name,
									productKey: initialDigitalAssetItem.productKey
								},
								deleted: initialDigitalAssetItem.deleted
						  };
					if (addInitial) {
						const counterpartIds: { value: number; deleted: boolean }[] = [];
						const counterpartDraftIds: string[] = [];
						if (!shipStationSkuUpdated && initialShipStationItem.id) {
							counterpartIds.push({ value: initialShipStationItem.id, deleted: initialIsDigital });
						} else if (!initialIsDigital) {
							counterpartDraftIds.push(initialShipStationItem.draftId);
						}
						if (!digitalAssetKeyUpdated && initialDigitalAssetItem.id) {
							counterpartIds.push({ value: initialDigitalAssetItem.id, deleted: !initialIsDigital });
						} else if (initialIsDigital) {
							counterpartDraftIds.push(initialDigitalAssetItem.draftId);
						}
						// Mark original subscription as inactive
						if (initial.id) {
							body.userSubscriptionTypes.push({
								id: initial.id,
								draftId: initial.draftId,
								nextSubscriptionTypeId: initial.nextSubscriptionTypeId,
								nextSubscriptionTypeDraftId: initial.nextSubscriptionTypeDraftId,
								userSubscriptionTypeCategoryId: initial.isQuestionBankOnly
									? UserSubscriptionTypeCategoriesEnum.QuestionBank
									: UserSubscriptionTypeCategoriesEnum.Course,
								name: initial.name,
								description: initial.description,
								isRecurring: initial.isRecurring,
								isTrial: initial.isTrial,
								isActive: false,
								deleted: !!initial.deleted
							});
						}
						// Add new subscription
						body.userSubscriptionTypes.push({
							id: 0,
							draftId: initial.draftId,
							nextSubscriptionTypeId: initial.nextSubscriptionTypeId,
							nextSubscriptionTypeDraftId: initial.nextSubscriptionTypeDraftId,
							userSubscriptionTypeCategoryId: initial.isQuestionBankOnly
								? UserSubscriptionTypeCategoriesEnum.QuestionBank
								: UserSubscriptionTypeCategoriesEnum.Course,
							name: initial.name,
							description: initial.description,
							isRecurring: initial.isRecurring,
							isTrial: initial.isTrial,
							isDefault: initial.isDefault,
							isActive: true,
							deleted: !!initial.deleted,
							externalIntegrationDataItems: [
								bookItem,
								{
									id: 0,
									draftId: initialStripeItem.draftId,
									integrationId: ExternalIntegrationIds.Stripe,
									subscriptionTypeId: 0,
									counterpartIds,
									counterpartDraftIds,
									data: { planId: initialStripeItem.planId }
								},
								...addons.filter(f => f.deleted === false)
							]
						});
					} else if (addRecurring || addRecurringQuestionBankOnly || addonsUpdate) {
						//Update nextSubscriptionTypeId
						body.userSubscriptionTypes.push({
							id: initial.id,
							draftId: initial.draftId,
							nextSubscriptionTypeId: initial.nextSubscriptionTypeId,
							nextSubscriptionTypeDraftId: initial.nextSubscriptionTypeDraftId,
							name: initial.name,
							description: initial.description,
							isRecurring: initial.isRecurring,
							isTrial: initial.isTrial,
							isActive: initial.isActive,
							deleted: !!initial.deleted
						});
					}
					if (addRecurring || addonsUpdate) {
						// Mark original subscription as inactive
						if (!!recurring.id) {
							body.userSubscriptionTypes.push({
								id: recurring.id,
								draftId: recurring.draftId,
								nextSubscriptionTypeId: recurring.nextSubscriptionTypeId,
								nextSubscriptionTypeDraftId: recurring.nextSubscriptionTypeDraftId,
								name: recurring.name,
								description: recurring.description,
								isRecurring: recurring.isRecurring,
								isTrial: recurring.isTrial,
								isActive: false,
								deleted: !!recurring.deleted
							});
						}
						// Add new subscription
						body.userSubscriptionTypes.push({
							id: 0,
							draftId: recurring.draftId,
							nextSubscriptionTypeId: recurring.nextSubscriptionTypeId,
							nextSubscriptionTypeDraftId: recurring.nextSubscriptionTypeDraftId,
							name: recurring.name,
							description: recurring.description,
							isRecurring: recurring.isRecurring,
							isAppleSubscription: false,
							isTrial: recurring.isTrial,
							isDefault: recurring.isDefault,
							isActive: true,
							deleted: !!recurring.deleted,
							externalIntegrationDataItems: [
								{
									...recurringStripeItem,
									id: 0,
									integrationId: ExternalIntegrationIds.Stripe,
									subscriptionTypeId: 0,
									counterpartIds: [],
									counterpartDraftIds: [],
									data: { planId: recurringStripeItem.planId }
								},
								...addons.reduce((acc, f) => {
									if (f.deleted === false) {
										acc.push({ ...f, parentDraftId: recurringStripeItem.draftId });
									}
									return acc;
								}, [] as UserSubscriptionTypeExternalIntegrationDataItemUpsertDto[])
							]
						});
					}
					if (addRecurringQuestionBankOnly || (addonsUpdate && recurringQuestionBankOnlyStripeItem.planId)) {
						// Mark original subscription as inactive
						if (!!recurringQuestionBankOnly.id) {
							body.userSubscriptionTypes.push({
								id: recurringQuestionBankOnly.id,
								draftId: recurringQuestionBankOnly.draftId,
								nextSubscriptionTypeId: recurringQuestionBankOnly.nextSubscriptionTypeId,
								nextSubscriptionTypeDraftId: recurringQuestionBankOnly.nextSubscriptionTypeDraftId,
								name: recurringQuestionBankOnly.name,
								description: recurringQuestionBankOnly.description,
								isRecurring: recurringQuestionBankOnly.isRecurring,
								isTrial: recurringQuestionBankOnly.isTrial,
								isActive: false,
								deleted: !!recurringQuestionBankOnly.deleted
							});
						}
						// Add new subscription
						body.userSubscriptionTypes.push({
							id: 0,
							draftId: recurringQuestionBankOnly.draftId,
							nextSubscriptionTypeId: recurringQuestionBankOnly.nextSubscriptionTypeId,
							nextSubscriptionTypeDraftId: recurringQuestionBankOnly.nextSubscriptionTypeDraftId,
							userSubscriptionTypeCategoryId: UserSubscriptionTypeCategoriesEnum.QuestionBank,
							name: recurringQuestionBankOnly.name,
							description: recurringQuestionBankOnly.description,
							isRecurring: recurringQuestionBankOnly.isRecurring,
							isTrial: recurringQuestionBankOnly.isTrial,
							isActive: true,
							isDefault: true,
							deleted: !!recurringQuestionBankOnly.deleted,
							externalIntegrationDataItems: [
								{
									...recurringQuestionBankOnlyStripeItem,
									id: 0,
									integrationId: ExternalIntegrationIds.Stripe,
									subscriptionTypeId: 0,
									counterpartIds: [],
									counterpartDraftIds: [],
									data: { planId: recurringQuestionBankOnlyStripeItem.planId }
								},
								...addons.reduce((acc, f) => {
									if (f.deleted === false) {
										acc.push({ ...f, parentDraftId: recurringQuestionBankOnlyStripeItem.draftId });
									}
									return acc;
								}, [] as UserSubscriptionTypeExternalIntegrationDataItemUpsertDto[])
							]
						});
					}
					if (appleProductKeyChanged) {
						// Mark original subscription as inactive
						if (!!appleInitial.id) {
							body.userSubscriptionTypes.push({
								id: appleInitial.id,
								draftId: appleInitial.draftId,
								nextSubscriptionTypeId: appleInitial.nextSubscriptionTypeId,
								nextSubscriptionTypeDraftId: appleInitial.nextSubscriptionTypeDraftId,
								name: appleInitial.name,
								description: appleInitial.description,
								isActive: false,
								isAppleSubscription: true
							});
							body.userSubscriptionTypes.push({
								id: appleRecurring.id,
								draftId: appleRecurring.draftId,
								nextSubscriptionTypeId: appleRecurring.nextSubscriptionTypeId,
								nextSubscriptionTypeDraftId: appleRecurring.nextSubscriptionTypeDraftId,
								name: appleRecurring.name,
								description: appleRecurring.description,
								isActive: false,
								isAppleSubscription: true
							});
						}
						// Add new subscription
						body.userSubscriptionTypes.push({
							id: 0,
							draftId: appleInitial.draftId,
							nextSubscriptionTypeId: appleInitial.nextSubscriptionTypeId,
							nextSubscriptionTypeDraftId: appleRecurring.draftId,
							userSubscriptionTypeCategoryId: UserSubscriptionTypeCategoriesEnum.Course,
							name: appleInitial.name,
							description: appleInitial.description,
							isRecurring: appleInitial.isRecurring,
							isTrial: false,
							isActive: true,
							deleted: !!initialAppleProductItem.deleted,
							isAppleSubscription: true,
							externalIntegrationDataItems: [
								{
									id: 0,
									integrationId: ExternalIntegrationIds.Apple,
									subscriptionTypeId: 0,
									counterpartIds: [],
									counterpartDraftIds: [],
									data: { productKey: initialAppleProductItem.productKey, name: initialAppleProductItem.name }
								}
							]
						});

						// Add new subscription
						body.userSubscriptionTypes.push({
							id: 0,
							draftId: appleRecurring.draftId,
							nextSubscriptionTypeId: appleRecurring.nextSubscriptionTypeId,
							nextSubscriptionTypeDraftId: appleRecurring.draftId,
							userSubscriptionTypeCategoryId: UserSubscriptionTypeCategoriesEnum.Course,
							name: appleRecurring.name,
							description: appleRecurring.description,
							isRecurring: appleRecurring.isRecurring,
							isTrial: false,
							isActive: true,
							deleted: !!initialAppleProductItem.deleted,
							isAppleSubscription: true,
							externalIntegrationDataItems: [
								{
									id: 0,
									integrationId: ExternalIntegrationIds.Apple,
									subscriptionTypeId: 0,
									counterpartIds: [],
									counterpartDraftIds: [],
									data: { productKey: initialAppleProductItem.productKey, name: initialAppleProductItem.name }
								}
							]
						});
					}
					if (!hasTrial) {
						if (trial.id) {
							addTrial = true;
							trial.isActive = false;
						} else {
							addTrial = false;
						}
					} else {
						addTrial = !(trial.id && !addInitial);
					}
					if (addTrial) {
						body.userSubscriptionTypes.push({
							id: trial.id,
							draftId: trial.draftId,
							nextSubscriptionTypeId: trial.nextSubscriptionTypeId,
							nextSubscriptionTypeDraftId: trial.nextSubscriptionTypeDraftId,
							name: trial.name,
							description: trial.description,
							isRecurring: trial.isRecurring,
							isTrial: trial.isTrial,
							isActive: trial.isActive,
							deleted: !!trial.deleted
						});
					}
					if (updateAdditionalOffering) {
						//De-activate previous offerings
						originalAdditonalInitialExternalIntegrationData.forEach(item => {
							const current = additionalInitial.find(i => i.id == item.id)!;
							body!.userSubscriptionTypes!.push({
								id: item.id,
								name: current.name,
								description: current.description,
								isActive: false
							});
						});
						//Add new offerings
						additionalInitial
							.filter(item => !item.stripeItem.deleted)
							.forEach(item => {
								const { stripeItem } = item;
								body!.userSubscriptionTypes!.push({
									id: 0,
									draftId: item.draftId,
									nextSubscriptionTypeId: item.nextSubscriptionTypeId,
									nextSubscriptionTypeDraftId: item.nextSubscriptionTypeDraftId,
									name: item.name,
									userSubscriptionTypeCategoryId: item.isQuestionBankOnly
										? UserSubscriptionTypeCategoriesEnum.QuestionBank
										: UserSubscriptionTypeCategoriesEnum.Course,
									description: item.description,
									isRecurring: item.isRecurring,
									isTrial: item.isTrial,
									isDefault: item.isDefault,
									isActive: true,
									deleted: !!item.deleted,
									externalIntegrationDataItems: [
										bookItem,
										{
											...stripeItem,
											id: 0,
											integrationId: ExternalIntegrationIds.Stripe,
											subscriptionTypeId: 0,
											counterpartIds: [],
											counterpartDraftIds: [],
											data: { planId: stripeItem.planId }
										},
										...addons.filter(f => f.deleted === false).map(i => ({ ...i, parentDraftId: stripeItem.draftId }))
									]
								});
							});
					}
					if (updateAdditionalRecurringOffering) {
						//De-activate previous offerings
						originalAdditonalRecurringExternalIntegrationData.forEach(item => {
							const current = additionalRecurring.find(i => i.id == item.id)!;
							body!.userSubscriptionTypes!.push({
								id: item.id,
								name: current.name,
								description: current.description,
								isActive: false
							});
						});
						//Add new offerings
						additionalRecurring.forEach(item => {
							const { stripeItem } = item;
							if (!stripeItem.deleted) {
								body!.userSubscriptionTypes!.push({
									id: 0,
									draftId: item.draftId,
									nextSubscriptionTypeId: item.nextSubscriptionTypeId,
									nextSubscriptionTypeDraftId: item.nextSubscriptionTypeDraftId,
									name: item.name,
									description: item.description,
									userSubscriptionTypeCategoryId: UserSubscriptionTypeCategoriesEnum.Course,
									isRecurring: item.isRecurring,
									isTrial: item.isTrial,
									isDefault: item.isDefault,
									isActive: true,
									deleted: !!item.deleted,
									externalIntegrationDataItems: [
										{
											...stripeItem,
											id: 0,
											integrationId: ExternalIntegrationIds.Stripe,
											subscriptionTypeId: 0,
											counterpartIds: [],
											counterpartDraftIds: [],
											data: { planId: stripeItem.planId }
										},
										...addons.reduce((acc, f) => {
											if (f.deleted === false) {
												acc.push({ ...f, parentDraftId: stripeItem.draftId });
											}
											return acc;
										}, [] as UserSubscriptionTypeExternalIntegrationDataItemUpsertDto[])
									]
								});
							}
						});
					}
				}
			}
			if (!form || form === "courseFeaturesForm") {
				dispatch(validateForm({ formStatePath: "courseFeaturesForm", markInputsAsDirty: true }));
				const { courseFeaturesForm } = getState().courseAddEdit;
				const { marketingPoints } = getState().courseAddEdit.data.data;
				if (!courseFeaturesForm.valid) {
					dispatch(_emit("Please fill in all of the course fetures fields correctly and try again.", "error"));
					return;
				} else {
					const { rawData: courseFeaturesFormData } = courseFeaturesForm;
					body = {
						data: {
							additionalInformation: courseFeaturesFormData.additionalInfo,
							marketingPoints: marketingPoints
						}
					};
				}
			}
			if (!courseData.id) {
				const newCourse = await coursesService.create(body as CourseCreateDto);
				dispatch(setStateValue({ key: "newCourseId", value: newCourse.id }));
				dispatch(_emit("Course created successfully.", "success"));
				return;
			}
			await coursesService.update({
				filters: { id: courseData.id },
				data: body as CourseUpdateDataDto
			});
			dispatch(fetchData(courseData.id, { stateLoaderKey: "saveIsLoading" }));
			dispatch(_emit("Course updated successfully.", "success"));
		} catch (e) {
			console.error(e);
			dispatch(_emit("An error has occurred while saving your data.", "error"));
		} finally {
			dispatch(setStateValue({ key: "saveIsLoading", value: false }));
		}
	};
};

export const updatePublishedState = (): AppThunk => {
	return async (dispatch, getState) => {
		try {
			dispatch(setStateValue({ key: "publishIsLoading", value: true }));
			const { courseAddEdit: state } = getState();
			const { courseForm, data: course } = state;
			const { raw } = await coursesService.update({
				filters: { id: course.id },
				data: { isActive: !courseForm.inputs.isActive.value } as CourseUpdateDataDto
			});
			const isActive = raw[0].isActive;
			dispatch(setStateValue({ key: "data.isActive", value: isActive }));
			dispatch(setStateValue({ key: "courseForm.inputs.isActive.value", value: isActive }));
			dispatch(setStateValue({ key: "publishIsLoading", value: false }));
			dispatch(_emit(`Course ${isActive ? "published" : "unpublished"} successfully.`, "success"));
		} catch (e) {
			console.error(e);
			dispatch(_emit("An error has occurred while saving your data.", "error"));
		}
	};
};

export default courseAddEditSlice.reducer;
