import React, { useState } from "react";

import { Box, Collapse, TableContainer } from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";

import Paper from "@material-ui/core/Paper";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { DeleteSharp, EditSharp } from "@material-ui/icons";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import { ActionMenu } from "@remar/shared/dist/components/ActionMenu";

import { ColumnHeader, StyledCellAddNewLessonText, StyledCellText, StyledTableCell, useStyles } from "./styles";

export const ExpendedRow = ({ data, editLessonHandle, handleDelete }) => {
	const { name, id, subjectId } = data;
	const [show, setShow] = useState(false);
	const classes = useStyles();

	return (
		<Box
			className={classes.expandRowGap}
			display="flex"
			onMouseEnter={() => setShow(true)}
			onMouseLeave={() => setShow(false)}
		>
			<StyledCellText>{name}</StyledCellText>
			<Box alignItems="center" display={show ? "flex" : "none"} className={classes.iconsGap}>
				<EditSharp
					onClick={() => editLessonHandle(name, id, subjectId)}
					className={classes.iconClass}
					style={{ width: "12px", height: "12px", color: "#d3d6e0" }}
				/>
				<DeleteSharp
					onClick={() => handleDelete({ id, type: "lesson" })}
					className={classes.iconClass}
					style={{ width: "12px", height: "12px", color: "#d3d6e0" }}
				/>
			</Box>
		</Box>
	);
};

function Row(props) {
	const {
		row,
		addNewLessonHandle,
		editSubjectHandle,
		handleDelete,
		editLessonHandle,
		customClass,
		index,
		page,
		perPage
	} = props;
	const [open, setOpen] = useState(false);
	const prevRows = (page - 1) * perPage;
	return (
		<React.Fragment>
			<TableRow>
				<StyledTableCell> {index + 1 + prevRows}</StyledTableCell>
				<StyledTableCell width={800} component="th" scope="row">
					<StyledCellText>{row.name}</StyledCellText>
				</StyledTableCell>
				<StyledTableCell width={380} align="center">
					<StyledCellAddNewLessonText onClick={() => addNewLessonHandle(row.id)}>
						+ Add New Lesson
					</StyledCellAddNewLessonText>
				</StyledTableCell>
				<StyledTableCell width={380} align="center">
					<StyledCellText>{row.lessons.length}</StyledCellText>
				</StyledTableCell>

				<StyledTableCell width={50} align="center">
					<ActionMenu
						noPadding
						customMenuItems={[
							{
								label: "Edit",
								onClick: () => editSubjectHandle(row),
								visible: true,
								disabled: false
							},
							{
								label: "Delete",
								onClick: () => handleDelete({ id: row.id, type: "subject" }),
								visible: true,
								disabled: false
							}
						]}
					/>
				</StyledTableCell>

				<StyledTableCell width={10}>
					{row.lessons.length > 0 && (
						<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
							{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
					)}
				</StyledTableCell>
			</TableRow>
			<TableRow>
				<TableCell className={customClass} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box>
							<Table size="small">
								<TableBody>
									{row.lessons.map(lesson => (
										<TableRow key={lesson.id}>
											<TableCell style={{ borderBottom: 0 }}>
												<ExpendedRow
													data={{ name: lesson.name, id: lesson.id, subjectId: lesson.subjectId }}
													editLessonHandle={editLessonHandle}
													handleDelete={handleDelete}
												/>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}

export default function CollapsibleTable({
	rows,
	handleDelete,
	addNewLessonHandle,
	editSubjectHandle,
	editLessonHandle,
	page,
	perPage
}) {
	const classes = useStyles();

	return (
		<TableContainer component={Paper}>
			<Table className={classes.tableBgColor} aria-label="collapsible table">
				<TableHead>
					<TableRow>
						<TableCell>
							<ColumnHeader>Subject</ColumnHeader>
						</TableCell>
						<TableCell align="center">
							<ColumnHeader>Adding Lesson</ColumnHeader>
						</TableCell>
						<TableCell align="center">
							<ColumnHeader>Lessons</ColumnHeader>
						</TableCell>
						<TableCell />
						<TableCell />
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row, index) => (
						<Row
							customClass={classes.rowPadding}
							handleDelete={handleDelete}
							addNewLessonHandle={addNewLessonHandle}
							editSubjectHandle={editSubjectHandle}
							editLessonHandle={editLessonHandle}
							key={row.id}
							row={row}
							index={index}
							page={page}
							perPage={perPage}
						/>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
