import React, { useEffect, useMemo } from "react";

import { Box, Typography } from "@material-ui/core";
import Button from "@remar/shared/dist/components/Button";
import { Doughnut } from "@remar/shared/dist/components/Charts";
import Reports, { ModuleWrapper } from "@remar/shared/dist/components/Reports";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { useHistory } from "react-router-dom";

import {
	getCancellationReasonSummary,
	getFullState,
	getTotalStudentsByCourse
} from "store/features/Students/students.slice";
import { useAppDispatch, useAppSelector } from "store/hooks";

import { routes } from "core/constants";

import { trimText } from "utils/trimText";

const breadcrumbs = [
	{ title: "Dashboard", key: 0, link: "/" },
	{ title: "Manage Students", key: 1, link: routes.manageStudents.getPath() },
	{ title: "Subscription Cancellations", key: 2, link: routes.manageStudents.subscriptionCancellations.getPath() }
];

const SubscriptionCancellation = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();

	useEffect(() => {
		dispatch(getTotalStudentsByCourse());
	}, [dispatch]);

	const { cancellationReasonSummary, isLoading, studentStats } = useAppSelector(getFullState);

	const data = useMemo(() => {
		const summaryLabels = ["Total Students", "Recurring"];
		const summaryData = [studentStats?.totalStudents, studentStats?.totalRecurring];
		const summaryColors = ["hsl(125, 70%, 63%)", "hsl(353, 81%, 63%)"];

		studentStats?.courses?.forEach((c, i) => {
			const { courseName, trialUsersCount, initialUsersCount, recurringUsersCount } = c;
			summaryLabels.push(`${trimText(courseName)} Paid`);
			summaryData.push(initialUsersCount + recurringUsersCount);
			summaryColors.push(i % 2 ? "hsl(224, 44%, 51%)" : "hsl(45, 100%, 63%)");

			summaryLabels.push(`${trimText(courseName)} Trial`);
			summaryData.push(trialUsersCount);
			summaryColors.push(i % 2 ? "hsl(213, 94%, 63%)" : "hsl(274, 100%, 75%)");
		});

		return [
			{
				title: "Subscription cancellations",
				donutData: {
					labels: ["All student who wanted to cancel", "Students who canceled"],
					datasets: [
						{
							data: [
								(cancellationReasonSummary?.retainedStudents || 0) +
									(cancellationReasonSummary?.cancelledStudents || 0),
								cancellationReasonSummary?.cancelledStudents
							],
							backgroundColor: ["hsl(131, 64%, 50%)", "hsl(353, 81%, 63%)"]
						}
					]
				}
			},
			{
				title: "Cancellation reasons",
				donutData: {
					labels: [
						"I passed my NCLEX",
						"I completed the program",
						"I am taking NCLEX soon",
						"I had problems using the V2",
						"I was unsuccessful",
						"I need to take a break",
						"Renewing subscription is too expensive",
						"I want a different resource"
					],
					datasets: [
						{
							data: cancellationReasonSummary?.cancellationReasons.map(c => c.count),
							backgroundColor: [
								"hsl(125, 70%, 63%)",
								"hsl(353, 81%, 63%)",
								"hsl(224, 44%, 51%)",
								"hsl(45, 100%, 63%)",
								"hsl(213, 94%, 63%)",
								"hsl(274, 100%, 75%)",
								"hsl(21, 100%, 57%)",
								"hsl(226, 17%, 85%)"
							]
						}
					]
				}
			},
			{
				title: "Summary",
				donutData: {
					labels: summaryLabels,
					datasets: [
						{
							data: summaryData,
							backgroundColor: summaryColors
						}
					]
				}
			}
		];
	}, [cancellationReasonSummary, studentStats]);

	return (
		<Reports
			heading="Reports"
			breadcrumb={breadcrumbs}
			actions={
				<Button variant="filled" color="secondary" size={"large"} onClick={() => history.goBack()}>
					Back
				</Button>
			}
			onUpdate={d => dispatch(getCancellationReasonSummary(d))}
		>
			{isLoading || !cancellationReasonSummary || !studentStats ? (
				<ContentLoader />
			) : (
				data.map(({ title, donutData }, i) => (
					<ModuleWrapper item md={6} xs={12} key={i}>
						<Box className="report-card">
							<Box
								className="report-card-item"
								display="flex"
								flexDirection="column"
								justifyContent="space-between"
								alignItems="center"
								margin="auto"
							>
								<Typography>{title}</Typography>
								<Box my={2} className="chart-container" style={{ height: 256, width: "100%", minWidth: 550 }}>
									<Doughnut
										options={{
											responsive: true,
											maintainAspectRatio: false,
											cutout: "50%",
											plugins: {
												legend: {
													display: true,
													position: "right",
													labels: {
														pointStyle: "circle",
														boxHeight: 12,
														usePointStyle: true,
														textAlign: "left"
													}
												},
												title: {
													display: false
												}
											}
										}}
										data={donutData}
									/>
								</Box>
							</Box>
						</Box>
					</ModuleWrapper>
				))
			)}
		</Reports>
	);
};

export default SubscriptionCancellation;
