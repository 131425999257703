import React, { useEffect, useState } from "react";

import { Box, Breadcrumbs, Button, InputAdornment } from "@material-ui/core";
import { DeleteSharp, EditSharp, NavigateNext } from "@material-ui/icons";
import { TablePagination } from "@remar/shared/dist/components/TablePagination";
import { SimpleModal } from "@remar/shared/dist/modals/SimpleModal";
import { useDispatch, useSelector } from "react-redux";

import { fetchAllCourses, getFullState as getFullCourseState } from "store/features/Course/course.slice";
import {
	createLesson,
	createSubject,
	deleteLesson as deleteLessonAction,
	deleteSubject as deleteSubjectAction,
	fetchSubjects,
	getFullState,
	initialState,
	populateInputs,
	setStateValue,
	validateForm
} from "store/features/Subjects/Subjects.slice";

import { _emit } from "store/features/notifications/notifications.slice";

import { routes } from "core/constants";
import theme from "theme/default";

import CollapsibleTable from "./CollapsibleTable";
import {
	ActiveLink,
	ButtonsRow,
	CircularProgress,
	DeleteCancelButton,
	DeleteConfirmButton,
	DeleteConfirmContent,
	DeleteModalTitle,
	EmptyPanel,
	FieldLabel,
	FormModal,
	HeaderSplit,
	Link,
	ModalRow,
	ModalTitle,
	SearchPanel,
	SectionHeader,
	StyledCellText,
	CustomInput as StyledInput,
	SubjectContainer,
	SubjectIconContainer,
	SubjectModalContent,
	useStyles
} from "./styles";

const ManageSubjects = () => {
	const [showModal, setShowModal] = useState(false);
	const classes = useStyles();
	const [showLessonModal, setShowLessonModal] = useState(false);
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const [deleteableObject, setDeleteableObject] = useState<{ id: number; type: string } | undefined>();
	const { courses } = useSelector(getFullCourseState);
	const dispatch = useDispatch();
	const { subjectForm, isLoading, lessonForm, subjects, perPage, page, totalItems } = useSelector(getFullState);
	// WIP we might need this in future
	// const newSubjects = useMemo(() => {
	// 	const arr = [] as Record<string, unknown>[];
	// 	subjects.forEach(x => {
	// 		const { lessons, name, id, description } = x;
	// 		arr.push({ name, id, description: description, lessonsLength: lessons?.length || 0 });
	// 		if (lessons?.length)
	// 			arr.push({
	// 				_expandData: {
	// 					rowHeight: 72 * lessons.length,
	// 					oneRowHeight: 72,
	// 					data: lessons
	// 				}
	// 			});
	// 	});
	// 	return arr;
	// }, [subjects]);

	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };

	const addNewLessonHandle = id => {
		setShowLessonModal(true);
		dispatch(setStateValue({ key: "lessonForm.valid", value: false }));
		dispatch(
			populateInputs({
				inputsStatePath: "lessonForm.inputs",
				templatesStatePath: "lessonForm.templates",
				values: { subjectId: id }
			})
		);
	};

	const editSubjectHandle = data => {
		setShowModal(true);
		const courseIds = data?.allowedCourses;
		dispatch(setStateValue({ key: "subjectForm.inputs.courseIds.selectOptions", value: courses }));
		dispatch(
			populateInputs({
				inputsStatePath: "subjectForm.inputs",
				templatesStatePath: "subjectForm.templates",
				values: { ...data, courseIds }
			})
		);
	};

	const editLessonHandle = (name, id, subjectId) => {
		setShowLessonModal(true);
		dispatch(setStateValue({ key: "lessonForm.valid", value: false }));
		dispatch(
			populateInputs({
				inputsStatePath: "lessonForm.inputs",
				templatesStatePath: "lessonForm.templates",
				values: { name, id, subjectId }
			})
		);
	};

	// WIP we might need this in future
	// const args = {
	// 	height: 950,
	// 	autoHeight: false,
	// 	customStyle: { padding: 0, backgroundColor: "#22252d" },
	// 	columns: [
	// 		{
	// 			alignment: "left",
	// 			label: <ColumnHeader>Subject</ColumnHeader>,
	// 			width: 800,
	// 			Cell: ({ rowData: { name } }: { rowData: { name: string } }) => {
	// 				return <StyledCellText>{name}</StyledCellText>;
	// 			},
	// 			dataKey: "name"
	// 		},
	// 		{
	// 			alignment: "left",
	// 			width: 350,
	// 			label: <ColumnHeader className={classes.textCenter}>Adding Lesson</ColumnHeader>,
	// 			Cell: ({ rowData: { id } }: { rowData: { id: number } }) => {
	// 				return (
	// 					<StyledCellAddNewLessonText
	// 						onClick={() => {
	// 							setShowLessonModal(true);
	// 							dispatch(setStateValue({ key: "lessonForm.valid", value: false }));
	// 							dispatch(
	// 								populateInputs({
	// 									inputsStatePath: "lessonForm.inputs",
	// 									templatesStatePath: "lessonForm.templates",
	// 									values: { subjectId: id }
	// 								})
	// 							);
	// 						}}
	// 					>
	// 						+ Add New Lesson
	// 					</StyledCellAddNewLessonText>
	// 				);
	// 			},
	// 			dataKey: "addingLesson"
	// 		},
	// 		{
	// 			width: 350,
	// 			alignment: "left",
	// 			label: <ColumnHeader className={classes.textCenter}>Lessons</ColumnHeader>,
	// 			Cell: ({ rowData: { lessonsLength } }) => {
	// 				return (
	// 					<StyledCellText className={`${classes.noPadding} ${classes.textCenter}`}>{lessonsLength}</StyledCellText>
	// 				);
	// 			},
	// 			dataKey: "lessonsLength"
	// 		},
	// 		{
	// 			alignment: "right",
	// 			label: "",
	// 			width: 100,
	// 			Cell: ({ rowData: { name, id } }: { rowData: { id: number; name: string } }) => {
	// 				return (
	// 					<ActionMenu
	// 						onEditClick={() => {
	// 							setShowModal(true);
	// 							dispatch(setStateValue({ key: "subjectForm.valid", value: false }));
	// 							dispatch(
	// 								populateInputs({
	// 									inputsStatePath: "subjectForm.inputs",
	// 									templatesStatePath: "subjectForm.templates",
	// 									values: { name, id }
	// 								})
	// 							);
	// 						}}
	// 						onDeleteClick={() => handleDelete({ id, type: "subject" })}
	// 					/>
	// 				);
	// 			},
	// 			dataKey: "menu"
	// 		},
	// 		{
	// 			dataKey: "__expanded__",
	// 			width: 0,
	// 			className: "virtualized-table__inner-column_expanded",
	// 			Cell: ({ rowData: { _expandData } }) => {
	// 				return (
	// 					<React.Fragment>
	// 						{_expandData && (
	// 							<Table
	// 								customStyle={{ padding: 0, backgroundColor: "#22252d" }}
	// 								height={_expandData.rowHeight}
	// 								data={_expandData.data}
	// 								hideHeader
	// 								columns={[
	// 									{
	// 										alignment: "left",
	// 										label: <ColumnHeader>Lesson</ColumnHeader>,
	// 										width: 800,
	// 										Cell: ({
	// 											rowData: { name, id, subjectId }
	// 										}: {
	// 											rowData: { name: string; id: number; subjectId: number };
	// 										}) => expendedRow({ name, id, subjectId }, setShowLessonModal, handleDelete),
	// 										dataKey: "name"
	// 									}
	// 								]}
	// 								multigrid
	// 							/>
	// 						)}
	// 					</React.Fragment>
	// 				);
	// 			}
	// 		}
	// 	],
	// 	multigrid: true,
	// 	expandable: true
	// };

	useEffect(() => {
		dispatch(fetchSubjects({}));
		dispatch(fetchAllCourses());
	}, [dispatch]);

	useEffect(() => {
		if (courses?.length) {
			dispatch(setStateValue({ key: "subjectForm.inputs.courseIds.selectOptions", value: courses }));
		}
	}, [courses, dispatch]);

	const handleDelete = data => {
		setShowDeleteConfirm(true);
		setDeleteableObject(data);
	};

	return (
		<SubjectContainer>
			<HeaderSplit>
				<div>
					<SectionHeader>Manage Subjects</SectionHeader>
					<Breadcrumbs separator={<NavigateNext fontSize="small" />}>
						<Link to={routes.questionbank.getPath()}>Question Bank</Link>
						<ActiveLink>Manage Subjects</ActiveLink>
					</Breadcrumbs>
				</div>
				<div>
					<Button
						variant={"contained"}
						color="primary"
						onClick={() => {
							setShowModal(true);
							dispatch(setStateValue({ key: "subjectForm.valid", value: false }));
						}}
						disabled={isLoading}
					>
						Add New Subject
					</Button>
				</div>
			</HeaderSplit>

			<SearchPanel>
				<div>Subjects</div>
			</SearchPanel>

			{isLoading ? (
				<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
			) : !subjects.length ? (
				<EmptyPanel>There is no subjects yet</EmptyPanel>
			) : (
				<>
					<CollapsibleTable
						handleDelete={handleDelete}
						editSubjectHandle={editSubjectHandle}
						addNewLessonHandle={addNewLessonHandle}
						editLessonHandle={editLessonHandle}
						rows={subjects}
						page={page}
						perPage={perPage}
					/>
					{/* WIP we might need this in future */}
					{/* <Table {...args} data={newSubjects || []} /> */}
					<TablePagination
						count={totalItems}
						page={page}
						onChange={(event, page) => dispatch(fetchSubjects({ page }))}
						rowsPerPage={perPage}
					/>
				</>
			)}

			<SimpleModal
				theme={theme}
				title={subjectForm?.inputs?.id?.value ? "Edit Subject" : "Add New Subject"}
				open={showModal}
				headerClass={classes.noHeaderMargin}
				onClose={() => {
					dispatch(setStateValue({ key: "subjectForm", value: initialState.subjectForm }));
					setShowModal(false);
				}}
				text={""}
				modalWidth={600}
				extraContent={
					<>
						<FieldLabel>
							Subject Name
							<StyledInput
								width={150}
								mr={2}
								theme={theme}
								options={{ ...defaultCustomInputOptions, inputData: subjectForm.inputs.name }}
							/>
						</FieldLabel>
						<ModalRow>
							<Box width={"50%"} mr={4}>
								<FieldLabel>
									Min % for CAT
									<StyledInput
										width={150}
										mr={2}
										InputProps={{
											endAdornment: <InputAdornment position="end">%</InputAdornment>
										}}
										theme={theme}
										options={{ ...defaultCustomInputOptions, inputData: subjectForm.inputs.minimumPercentage }}
									/>
								</FieldLabel>
							</Box>
							<Box width={"50%"}>
								<FieldLabel>
									Max % for CAT
									<StyledInput
										mr={2}
										width={150}
										InputProps={{
											endAdornment: <InputAdornment position="end">%</InputAdornment>
										}}
										theme={theme}
										options={{ ...defaultCustomInputOptions, inputData: subjectForm.inputs.maximumPercentage }}
									/>
								</FieldLabel>
							</Box>
						</ModalRow>
						<ModalRow>
							<Box width="40%" textAlign="right">
								Courses
							</Box>
							<Box width="60%" marginLeft={4}>
								<StyledInput
									theme={theme}
									options={{ ...defaultCustomInputOptions, inputData: subjectForm.inputs.allCourses }}
								/>
							</Box>
						</ModalRow>
						{!subjectForm.inputs.allCourses?.value && (
							<ModalRow>
								<Box marginLeft="auto">
									<StyledInput
										theme={theme}
										options={{ ...defaultCustomInputOptions, inputData: subjectForm.inputs.courseIds }}
									/>
								</Box>
							</ModalRow>
						)}
						<ModalRow>
							<StyledInput
								width={150}
								mr={2}
								theme={theme}
								options={{ ...defaultCustomInputOptions, inputData: subjectForm.inputs.isAvailableForTrial }}
							/>
						</ModalRow>
						<FieldLabel>
							Description
							<StyledInput
								width={150}
								mr={2}
								theme={theme}
								options={{ ...defaultCustomInputOptions, inputData: subjectForm.inputs.description }}
							/>
						</FieldLabel>
					</>
				}
				footer={
					<>
						<Button variant={"contained"} className={classes.cancelBtn} onClick={() => setShowModal(false)}>
							Cancel
						</Button>
						<Button
							color="primary"
							variant={"contained"}
							onClick={() => {
								if (subjectForm.valid) {
									dispatch(createSubject(() => setShowModal(false)));
								}
							}}
						>
							{subjectForm?.inputs?.id?.value ? "Save Changes" : "Add New Subject"}
						</Button>
					</>
				}
			/>

			<FormModal
				open={showLessonModal}
				onClose={() => {
					dispatch(setStateValue({ key: "lessonForm", value: initialState.lessonForm }));
					setShowLessonModal(false);
				}}
				disableEnforceFocus
				disableAutoFocus
			>
				<SubjectModalContent>
					<ModalTitle>{lessonForm?.inputs?.id?.value ? "Rename Lesson" : "Add New Lesson"}</ModalTitle>

					<FieldLabel>
						Lesson Name
						<StyledInput
							width={150}
							mr={2}
							theme={theme}
							options={{ ...defaultCustomInputOptions, inputData: lessonForm.inputs.name }}
						/>
					</FieldLabel>

					<ButtonsRow>
						<Button
							variant={"contained"}
							color="primary"
							onClick={() => {
								if (lessonForm.valid) {
									setShowLessonModal(false);
									dispatch(createLesson());
								}
							}}
						>
							{lessonForm?.inputs?.id?.value ? "Save Changes" : "Add New Lesson"}
						</Button>
					</ButtonsRow>
				</SubjectModalContent>
			</FormModal>

			<FormModal
				open={showDeleteConfirm}
				onClose={() => {
					setShowDeleteConfirm(false);
					setDeleteableObject(undefined);
				}}
				disableEnforceFocus
				disableAutoFocus
			>
				<DeleteConfirmContent>
					<DeleteModalTitle>Delete {deleteableObject?.type}</DeleteModalTitle>

					<SubjectIconContainer>
						<div>Are you sure you want to delete this {deleteableObject?.type}?</div>
					</SubjectIconContainer>
					<ButtonsRow>
						<DeleteCancelButton variant={"contained"} onClick={() => setShowDeleteConfirm(false)}>
							No, Cancel
						</DeleteCancelButton>
						<DeleteConfirmButton
							variant={"contained"}
							onClick={() => {
								setShowDeleteConfirm(false);
								if (deleteableObject) {
									deleteableObject.type === "subject" && dispatch(deleteSubjectAction(deleteableObject.id));
									deleteableObject.type === "lesson" && dispatch(deleteLessonAction(deleteableObject.id));
								}
							}}
						>
							Yes, Delete
						</DeleteConfirmButton>
					</ButtonsRow>
				</DeleteConfirmContent>
			</FormModal>
		</SubjectContainer>
	);
};

export const ExtendedRows = ({ data, setShowLessonModal, handleDelete }) => {
	const { name, id, subjectId } = data;
	const [show, setShow] = useState(false);
	const classes = useStyles();
	const dispatch = useDispatch();

	return (
		<Box
			className={classes.expandRowGap}
			display="flex"
			onMouseEnter={() => setShow(true)}
			onMouseLeave={() => setShow(false)}
		>
			<StyledCellText>{name}</StyledCellText>
			<Box display={show ? "flex" : "none"} className={classes.iconsGap}>
				<EditSharp
					onClick={() => {
						setShowLessonModal(true);
						dispatch(setStateValue({ key: "lessonForm.valid", value: false }));
						dispatch(
							populateInputs({
								inputsStatePath: "lessonForm.inputs",
								templatesStatePath: "lessonForm.templates",
								values: { name, id, subjectId }
							})
						);
					}}
					className={classes.iconClass}
					style={{ width: "12px", height: "12px", color: "#d3d6e0" }}
				/>
				<DeleteSharp
					onClick={() => handleDelete({ id, type: "lesson" })}
					className={classes.iconClass}
					style={{ width: "12px", height: "12px", color: "#d3d6e0" }}
				/>
			</Box>
		</Box>
	);
};

export default ManageSubjects;
