import React, { useEffect } from "react";

import { Box, Button, Card, CircularProgress, Divider, Grid, IconButton, Modal, Typography } from "@material-ui/core";

import { Close as CloseIcon } from "@material-ui/icons";

import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { useStyles } from "@remar/shared/dist/styles";
import { formatISO, getHours, getMinutes, set } from "date-fns";

import { Form, Formik } from "formik";
import isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { createCoupon, getCouponProducts, getCoupons, updateCoupon } from "store/features/Coupons/coupons.slice";
import { fetchAllCourses } from "store/features/Course/course.slice";

import { couponsSchema } from "./schema";

import { Label } from "./styles";

import { FormikField } from "../../../../Components/FormikFields";

const GridItem = ({ label, component }) => (
	<Grid item xs={6}>
		<Box mt={1} mb={1}>
			<Label>{label}</Label>
		</Box>
		{component}
	</Grid>
);

export const SHIPPING_PRODUCT_ID = 4;
export const PRODUCTS_OPTIONS = {
	SHIPPING: "shipping",
	PRODUCTS: "products"
};
export const QUANTITY_OPTIONS = {
	UNLIMITED: "Unlimited",
	LIMITED: "Limited"
};

export type FormValuesType = {
	name: string;
	isPercentage: boolean;
	amount: number;
	type?: number;
	courses: number[];
	quantity: number;
	quantity_type: string;
	products_shipping: string;
	isAffiliateCoupon: boolean;
	validFrom: Date;
	startTime: Date;
	expiresOn: Date;
	endTime: Date;
	neverExpire: boolean;
};

const initialFormValues: FormValuesType = {
	name: "",
	isPercentage: true,
	amount: 0,
	type: undefined,
	courses: [],
	quantity: 1,
	quantity_type: QUANTITY_OPTIONS.UNLIMITED,
	isAffiliateCoupon: false,
	products_shipping: "products",
	validFrom: new Date(),
	startTime: new Date(),
	expiresOn: new Date(),
	endTime: new Date(),
	neverExpire: false
};

type CreateEditCouponsProps = {
	initObj: null | FormValuesType;
	onClose: () => void;
};
const CreateEditCoupons = ({ initObj, onClose }: CreateEditCouponsProps) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { loadingCouponTypes, loadingCouponProducts, couponProducts, couponTypes, loadCreateCoupon } = useSelector(
		(state: RootState) => state.coupons
	);
	const { courses, isLoading } = useSelector((state: RootState) => state.course);

	useEffect(() => {
		dispatch(getCouponProducts());
		if (!courses) {
			dispatch(fetchAllCourses());
		}
	}, [dispatch]);

	const getProducts = values => {
		return Object.entries(values)
			.filter(([key, value]) => key.includes("product-") && value)
			.map(([key]) => ({ value: +key.split("product-")[1] }));
	};

	const handleSubmit = values => {
		const {
			id,
			name,
			code,
			type,
			quantity_type,
			quantity,
			amount,
			isPercentage,
			courses,
			products_shipping,
			validFrom,
			startTime,
			expiresOn,
			endTime,
			isAffiliateCoupon,
			neverExpire
		} = values;
		const validFromDate = set(validFrom, { hours: getHours(startTime), minutes: getMinutes(startTime) });
		const expiresOnDate = set(expiresOn, { hours: getHours(endTime), minutes: getMinutes(endTime) });

		const action = isEmpty(initObj) ? createCoupon : updateCoupon;
		dispatch(
			action({
				body: {
					id,
					name,
					code,
					isAffiliateCoupon,
					typeId: type,
					quantity: quantity_type === QUANTITY_OPTIONS.UNLIMITED ? 0 : +quantity,
					validFrom: formatISO(validFromDate),
					expiresOn: formatISO(expiresOnDate),
					neverExpire,
					data: {
						amount: +amount,
						isPercentage: products_shipping === PRODUCTS_OPTIONS.SHIPPING ? true : isPercentage
					},
					productIds:
						products_shipping === PRODUCTS_OPTIONS.SHIPPING ? [{ value: SHIPPING_PRODUCT_ID }] : getProducts(values),
					courseIds: courses.map(id => ({ value: id }))
				},
				cb: () => {
					dispatch(getCoupons({}));
					onClose();
				}
			})
		);
	};
	return (
		<Modal className={classes.modal} disableEnforceFocus disableAutoFocus open={true} onClose={onClose}>
			<Box width={700}>
				<Card style={{ padding: "20px 30px" }}>
					<Box display="flex" alignItems="center" justifyContent="space-between">
						<Typography variant="h6">{isEmpty(initObj) ? "Create New Coupon" : "Edit Coupon"}</Typography>
						<IconButton onClick={onClose}>
							<CloseIcon color="disabled" />
						</IconButton>
					</Box>
					{loadingCouponTypes || loadingCouponProducts || isLoading ? (
						<ContentLoader />
					) : (
						<Box mt={2}>
							<Formik
								initialValues={{ ...initialFormValues, ...initObj }}
								validationSchema={couponsSchema}
								onSubmit={values => handleSubmit(values)}
							>
								{({ isValid, dirty, values, setFieldValue }) => {
									const valid =
										isValid &&
										(values.products_shipping === PRODUCTS_OPTIONS.SHIPPING || values.isAffiliateCoupon
											? true
											: getProducts(values).length > 0);
									return (
										<Form>
											<Grid container spacing={2}>
												<GridItem
													label={"Coupon Name"}
													component={<FormikField name={"name"} type={"text"} placeholder="Enter coupon name" />}
												/>
												<GridItem
													label={"Coupon Code"}
													component={<FormikField name={"code"} type={"text"} placeholder="Enter coupon code" />}
												/>
												<GridItem
													label={"Discount"}
													component={
														<Box display={"flex"}>
															<FormikField
																name={"amount"}
																type={"number"}
																placeholder="Enter discount"
																fullWidth={true}
															/>
															<FormikField
																name={"isPercentage"}
																type={"btnGroup"}
																onChange={val => setFieldValue("isPercentage", val === "true")}
																placeholder="Enter discount"
																value={
																	values.products_shipping === PRODUCTS_OPTIONS.SHIPPING
																		? "true"
																		: values.isPercentage.toString()
																}
																options={[
																	{ label: "%", value: "true" },
																	{ label: "$", value: "false" }
																].filter(
																	option =>
																		(values.products_shipping === PRODUCTS_OPTIONS.SHIPPING &&
																			option.value === "true") ||
																		values.products_shipping !== PRODUCTS_OPTIONS.SHIPPING
																)}
															/>
														</Box>
													}
												/>
												<GridItem
													label={"Coupon Type"}
													component={
														<FormikField
															name={"type"}
															type={"select"}
															placeholder="Select coupon type"
															value={values.type}
															options={
																couponTypes ? couponTypes.items.map(({ id, name }) => ({ label: name, value: id })) : []
															}
														/>
													}
												/>
												<GridItem
													label={"Valid From"}
													component={
														<FormikField
															name={"validFrom"}
															type={"date"}
															value={values.validFrom}
															onChange={date => setFieldValue("validFrom", date)}
														/>
													}
												/>
												<GridItem
													label={"Start Time"}
													component={
														<FormikField
															name={"startTime"}
															type={"time"}
															value={values.startTime}
															onChange={date => setFieldValue("startTime", date)}
														/>
													}
												/>
												<GridItem
													label={"Expires On"}
													component={
														<>
															<FormikField
																name={"expiresOn"}
																type={"date"}
																disabled={values.neverExpire}
																value={values.expiresOn}
																onChange={date => setFieldValue("expiresOn", date)}
															/>
															<FormikField name="neverExpire" label="Never expire" type="checkbox" />
														</>
													}
												/>
												<GridItem
													label={"End time"}
													component={
														<FormikField
															name={"endTime"}
															disabled={values.neverExpire}
															type={"time"}
															value={values.endTime}
															onChange={date => setFieldValue("endTime", date)}
														/>
													}
												/>
												<GridItem
													label={"Products"}
													component={
														<Box>
															<FormikField name={"isAffiliateCoupon"} type={"checkbox"} label={"Affiliate Coupon"} />
															<FormikField
																name={"products_shipping"}
																type={"radio"}
																disabled={values.isAffiliateCoupon}
																options={[
																	{ label: "Products", value: PRODUCTS_OPTIONS.PRODUCTS },
																	{ label: "Shipping", value: PRODUCTS_OPTIONS.SHIPPING }
																]}
															/>
															<Divider />
															<Box display={"flex"} style={{ flexFlow: "row wrap" }}>
																{couponProducts?.items
																	.filter(item => item.id !== SHIPPING_PRODUCT_ID)
																	.map(({ id, name }) => (
																		<FormikField
																			key={id}
																			name={`product-${id}`}
																			type={"checkbox"}
																			label={name}
																			disabled={
																				values.products_shipping === PRODUCTS_OPTIONS.SHIPPING ||
																				values.isAffiliateCoupon
																			}
																		/>
																	))}
															</Box>
															<FormikField
																name={"courses"}
																type={"multiSelect"}
																placeholder="Select course(s)"
																value={values.courses}
																options={
																	courses
																		? courses.map(({ id, name }) => ({
																				label: name,
																				value: id
																		  }))
																		: []
																}
															/>
														</Box>
													}
												/>
												<GridItem
													label={"Quantity"}
													component={
														<Box>
															<FormikField
																name={"quantity_type"}
																type={"radio"}
																options={[
																	{ label: "Unlimited", value: QUANTITY_OPTIONS.UNLIMITED },
																	{ label: "Limited", value: QUANTITY_OPTIONS.LIMITED }
																]}
															/>
															<FormikField
																value={values.quantity}
																name={"quantity"}
																type={"inputNumberWithControls"}
																disabled={values.quantity_type === QUANTITY_OPTIONS.UNLIMITED}
																onChange={val => setFieldValue("quantity", val)}
															/>
														</Box>
													}
												/>
											</Grid>
											<Box mt={2}>
												<Divider />
											</Box>
											<Box mt={2} display={"flex"} justifyContent={"flex-end"} gridGap={16}>
												<Button className={classes.cancelBtn} disabled={loadCreateCoupon}>
													Cancel
												</Button>
												<Button
													variant={"contained"}
													color={"primary"}
													type={"submit"}
													startIcon={loadCreateCoupon && <CircularProgress size={20} />}
													disabled={!valid || !dirty || loadCreateCoupon}
												>
													{isEmpty(initObj) ? "Create New Coupon" : "Edit Coupon"}
												</Button>
											</Box>
										</Form>
									);
								}}
							</Formik>
						</Box>
					)}
				</Card>
			</Box>
		</Modal>
	);
};

export default CreateEditCoupons;
