import { buildRouteTree } from "@remar/shared/dist/utils/routeUtils";

export const routes = buildRouteTree({
	banners: null,
	catAvailability: null,
	communityPool: null,
	coupons: null,
	course: null,
	courseAddEdit: null,
	createLesson: null,
	discussionBoard: null,
	fileVault: null,
	home: null,
	lesson: null,
	location: null,
	manageLocations: null,
	manageSchools: null,
	manageStudents: {
		subscriptionCancellations: null
	},
	manageSubjects: null,
	manageTutorial: null,
	mediaLibrary: null,
	newReports: null,
	notifications: null,
	questionbank: null,
	reports: null,
	settings: null,
	signIn: null,
	signUp: null,
	students: null,
	videos: null
});
