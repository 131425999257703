import { BaseModel, User } from "@remar/shared/dist/models";

export interface EmailLogItem extends BaseModel {
	userId: number;
	user?: User;
	emailTemplateId: number;
	delivered: boolean;
	compiledData: {
		from: string;
		to: string;
		subject: string;
		body: string;
		attachments: Array<{ name: string; url: string }>;
	};
}

export enum DeliveryStatus {
	DELIVERED = "Delivered",
	PENDING = "Pending",
	FAILED = "Failed to Delivery"
}
