import React, { useEffect } from "react";

import { Box, Card, Chip, Divider, FormControl, MenuItem, Select, Typography, useTheme } from "@material-ui/core";
import Comment from "@remar/shared/dist/components/Comments/Comment";
import { TablePagination } from "@remar/shared/dist/components/TablePagination";
import useSearchParams from "@remar/shared/dist/hooks/useSearchParams";
import { Wrapper } from "@remar/shared/dist/layouts";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchAllCourses, getFullState } from "store/features/Course/course.slice";
import {
	createLessonComment,
	deleteCommentVote,
	deleteLessonComment,
	getAllComments,
	getFullDiscussionBoardState,
	lessonCommentsVoting,
	mockUpdateLessonComment,
	pinComment,
	updateLessonComment
} from "store/features/Lesson/lesson.slice";
import { useAppSelector } from "store/hooks";

import { IconListSvg } from "assets/icons";
import { routes } from "core/constants";

const DiscussionBoard = () => {
	const dispatch = useDispatch();
	const theme = useTheme();
	const history = useHistory();
	const params = useSearchParams();
	const page = Number(params.get("page")) || 1;
	const courseId = params.get("courseId") || "all";
	const { comments, isLoading, perPage, totalItems } = useAppSelector(getFullDiscussionBoardState);
	const { courses } = useAppSelector(getFullState);

	useEffect(() => {
		dispatch(getAllComments({ page, courseId: courseId === "all" ? undefined : Number(courseId) || undefined }));
	}, [dispatch, courseId, page]);

	useEffect(() => {
		dispatch(fetchAllCourses());
	}, [dispatch]);

	const onVote = (commentId: number | undefined, isLike: boolean) => {
		dispatch(
			lessonCommentsVoting({
				commentId,
				isLike
			})
		);
	};
	const onVoteDelete = (commentId, voteId, isLike, isTopLevel) => {
		dispatch(
			deleteCommentVote({
				commentId,
				voteId,
				isLike,
				isTopLevel
			})
		);
	};

	const onDelete = commentId => {
		dispatch(deleteLessonComment(commentId));
	};

	const onEdit = (text, lessonId, parentId, commentId) => {
		dispatch(
			updateLessonComment({
				data: {
					text,
					lessonId,
					parentId
				},
				filters: { id: commentId }
			})
		);
	};

	const onCreate = (text, lessonId, parentId) => {
		dispatch(
			createLessonComment({
				text,
				lessonId,
				parentId
			})
		);
	};

	const onMockDelete = (parentId, commentId, text) => {
		dispatch(
			mockUpdateLessonComment({
				parentId,
				commentId,
				text
			})
		);
	};

	const handlePinComment = (commentId, isPinned) =>
		dispatch(
			pinComment({
				commentId,
				isPinned,
				cb: () => dispatch(getAllComments({ page, courseId }))
			})
		);

	const updateFilters = ({ courseId, page }: { page: number; courseId?: number }) => {
		const search = new URLSearchParams({ page: page.toString(), courseId: courseId || "all" }).toString();
		history.push({
			pathname: routes.discussionBoard.getPath(),
			search
		});
	};

	return (
		<Wrapper heading="Comments" showLoader={isLoading}>
			<Card>
				<Box display="flex" justifyContent="space-between">
					<Typography>Latest Comments</Typography>
					<Box display="flex" alignItems="center" gridGap={8}>
						<IconListSvg fill={theme.palette.text.primary} width={25} height={25} style={{ marginRight: "5px" }} />
						<FormControl hiddenLabel size="small" variant="filled">
							<Select
								disableUnderline
								value={courseId}
								onChange={event => updateFilters({ page, courseId: event.target.value as number })}
								displayEmpty
								MenuProps={{
									anchorOrigin: {
										vertical: "bottom",
										horizontal: "left"
									},
									transformOrigin: {
										vertical: "top",
										horizontal: "left"
									},
									getContentAnchorEl: null
								}}
							>
								<MenuItem value="all">All Courses</MenuItem>
								{courses?.map(({ id, name }) => (
									<MenuItem key={id} value={id}>
										{name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
				</Box>

				<Box mt={4}>
					<Box>
						{comments.length !== 0 &&
							comments.map((comment, index) => (
								<Box key={comment.id} my={1}>
									<Box display={"flex"}>
										<Box width={"60%"}>
											<Comment
												loggedInUserId={0}
												user={comment.user}
												comment={comment}
												lessonId={comment.lessonId}
												isReply={true}
												padding={16}
												isAdminMode={true}
												onVote={onVote}
												onVoteDelete={onVoteDelete}
												onEdit={onEdit}
												onDelete={onDelete}
												onCreate={onCreate}
												onMockDelete={onMockDelete}
												pinComment={handlePinComment}
											/>
											{comment.courses.map(({ id, name }) => (
												<Chip key={id} label={name} color="primary" variant="outlined" />
											))}
										</Box>
										<Box
											display={"flex"}
											ml={3}
											style={{ cursor: "pointer" }}
											onClick={() => history.push(`${routes.lesson.getPath()}/${comment.lessonId}`)}
										>
											{comment.lesson?.mainImageUrl && (
												<Box mr={2}>
													<img
														width="200px"
														height="100px"
														src={
															comment.lesson.mainImageKey
																? comment.lesson.mainImageThumbnailUrl?.small250 || comment.lesson.mainImageUrl
																: "https://picsum.photos/200"
														}
														alt="lesson image"
													/>
												</Box>
											)}
											<Box display={"flex"} flexDirection={"column"}>
												<Typography variant={"caption"} color={"textSecondary"}>
													Lesson
												</Typography>
												<Typography>{comment.lesson?.name}</Typography>
											</Box>
										</Box>
									</Box>
									{comments.length > index + 1 && (
										<Box mt={1} mb={2}>
											<Divider style={{ width: "100%", height: "1px" }} />
										</Box>
									)}
								</Box>
							))}
						<TablePagination
							count={totalItems}
							page={page}
							onChange={(_, page) => updateFilters({ page, courseId })}
							rowsPerPage={perPage}
						/>
					</Box>
				</Box>
			</Card>
		</Wrapper>
	);
};

export default DiscussionBoard;
