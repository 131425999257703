export const URLS = {
	ACTIVITY_LOGS: "/activity-logs",
	ASSETS: "/assets",
	COUNTRIES: "/countries",
	COUPONS: "/coupons",
	COURSES: "/courses",
	COURSE_CHAPTERS: "/course-chapters",
	COURSE_CHAPTER_SECTIONS: "/course-chapter-sections",
	COURSE_CHAPTER_SECTION_LESSONS: "/course-chapter-section-lessons",
	FILES: "/files",
	FILEVAULTFOLDER: "/folders",
	GET_IMAGE_URL: "/image-url",
	LESSONS: "/lessons",
	LESSON_DRAFTS: "/lesson-drafts",
	LESSON_RANKS: "/lesson-ranks",
	LESSON_TYPES: "/lesson-types",
	LESSON_VIDEOS: "/lesson-videos",
	LOCATIONS: "/locations",
	LOCATION_PACKAGE: "/location-package",
	NOTIFICATIONS: "/notifications",
	QUESTIONS: "/questions",
	QUESTION_ANSWER_OPTIONS: "/question-answer-options",
	QUESTION_DIFFICULTY_LEVELS: "/question-difficulty-levels",
	QUESTION_TYPES: "/question-types",
	REPORTS: "/reports",
	SCHOOLS: "/schools",
	SUBJECTS: "/subjects",
	SUBJECT_CATEGORIES: "/subject-categories",
	SUBJECT_LESSONS: "/subject-lessons",
	UPLOAD: "/upload",
	USERS: "/users",
	USER_CUSTOM_TESTS: "/user-custom-tests",
	USER_EMAIL_LOGS: "/user-email-logs",
	USER_SUBSCRIPTION_TYPES: "/user-subscription-types"
};
