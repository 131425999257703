import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Button, CircularProgress } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import { secondsToHours, secondsToMinutes } from "date-fns";

import { isEmpty } from "lodash";

import { QuizResultsContainer, QuizResultsWrapper } from "./styles";

import { QuestionBankTestModes, QuestionDifficultyLevelTiers, UserCustomTestTypes } from "../../constants";
import useSearchParams from "../../hooks/useSearchParams";
import { EmptyState, TableContainer, Wrapper } from "../../layouts";
import ContentLoader from "../../layouts/TableContentLayout/components/ContentLoader";
import { IUserCustomTestResponse } from "../../services/userCustomTests/dto";
import { useStyles } from "../../styles";
import { IExtendedTheme } from "../../theme/default";
import { formatDate } from "../../utils/myAccountUtils";
import { IBreadCrumb } from "../HeaderContainer/HeaderContainer";
import { FilterTypes, IColumn, IMultipleCheckbox, MaterialTable } from "../MaterialTable";
import { ColumnHeader, StyledCellText, StyledCellWrapper } from "../Table/styles";
import QuestionResultInfo from "../TestResults/Components/QuestionResultInfo";
import ExtendedTestResults from "../TestResults/ExtendedTestResults";

type StudentTestResultsProps = {
	userId: string;
	isLoading: boolean;
	firstName: string;
	lastName: string;
	breadcrumb: Array<IBreadCrumb>;
	loadingStudentTests: boolean;
	onBack: () => void;
	studentTests: IUserCustomTestResponse | null;
	getStudentTests: (args: { userId: string; typeId?: string[]; page?: number; perPage?: number }) => void;
	getStudentTest: (args: {
		userId: string;
		testId: number;
		sideEffect: (test: IUserCustomTestResponse) => void;
	}) => void;
};

const StudentTestResults = ({
	userId,
	isLoading,
	loadingStudentTests,
	firstName,
	lastName,
	breadcrumb,
	onBack,
	studentTests,
	getStudentTest,
	getStudentTests
}: StudentTestResultsProps) => {
	const theme = useTheme<IExtendedTheme>();
	const params = useSearchParams();
	const testId = params.get("test");

	const classes = useStyles();

	const [testPreviewData, setTestPreviewData] = useState({});
	const [questionTypeFilter, setQuestionTypeFilter] = useState<string[]>([]);

	const getTestScoreText = useCallback(
		(score: number): { text: "Low" | "Fair" | "Good" | "Excellent"; color: string } => {
			if (score <= 0.49) {
				return { text: "Low", color: theme.palette.colors.danger[500] };
			} else if (score <= 0.6) {
				return { text: "Fair", color: theme.palette.colors.orange[300] };
			} else if (score <= 0.84) {
				return { text: "Good", color: theme.palette.colors.yellow[300] };
			} else {
				return { text: "Excellent", color: theme.palette.colors.success[500] };
			}
		},
		[theme]
	);

	const setPreviewData = testData => {
		setTestPreviewData({
			currentTest: testData,
			typeId: testData.typeId,
			quizResult: testData.userQuestionAttempts,
			quizAttemptedQuestions: testData.data.questions,
			...(testData.typeId === QuestionBankTestModes.CAT && {
				contentAreas: testData.data.result?.contentAreas
			}),
			...(testData.typeId === QuestionBankTestModes.CAT && {
				catAttemptedQuestionsCount: testData.userQuestionAttempts.length
			})
		});
	};

	useEffect(() => {
		getStudentTests({ userId });
	}, [userId]);

	useEffect(() => {
		if (testId) {
			getStudentTest({
				userId,
				testId: Number(testId),
				sideEffect: setPreviewData
			});
		}
	}, [testId, userId]);

	const checkboxFiltersValue = useMemo(
		() =>
			[
				{
					id: UserCustomTestTypes.CAT,
					label: "CAT",
					checked: false
				},
				{
					id: UserCustomTestTypes.Test,
					label: "Test",
					checked: false
				},
				{
					id: UserCustomTestTypes.Tutor,
					label: "Tutor Test",
					checked: false
				}
			].map(type =>
				questionTypeFilter.includes("" + type.id)
					? {
							...type,
							checked: true
					  }
					: type
			),
		[questionTypeFilter]
	);

	const tableColumns: Array<IColumn<IUserCustomTestResponse>> = useMemo(() => {
		return [
			{
				alignment: "left",
				label: <ColumnHeader>Test Name</ColumnHeader>,
				Cell: ({ rowData: { name } }) => (
					<StyledCellWrapper>
						<StyledCellText>{name}</StyledCellText>
					</StyledCellWrapper>
				),
				ChildCell: () => <></>,
				dataKey: "name"
			},
			{
				alignment: "center",
				label: <ColumnHeader>Test Type</ColumnHeader>,
				Cell: ({ rowData: { typeId } }) => (
					<StyledCellWrapper center={true}>
						<StyledCellText>{QuestionBankTestModes[typeId]}</StyledCellText>
					</StyledCellWrapper>
				),
				ChildCell: ({ rowData: { typeId } }) => (
					<StyledCellWrapper center={true}>
						<StyledCellText>{QuestionBankTestModes[typeId]}</StyledCellText>
					</StyledCellWrapper>
				),
				filter: {
					type: FilterTypes.MultipleCheckbox,
					data: {
						allFilters: {
							id: 0,
							label: "All Tests",
							checked: false
						},
						checkboxFilters: checkboxFiltersValue
					},
					onChange: ({ allFilters: { checked }, checkboxFilters }: IMultipleCheckbox) => {
						const typeId = checked
							? checkboxFilters.map(({ id }) => "" + id)
							: checkboxFilters.reduce((result, item) => {
									if (item.checked) {
										result.push(item.id.toString());
									}
									return result;
							  }, [] as string[]);
						setQuestionTypeFilter(typeId);
						getStudentTests({ userId, typeId });
					}
				},
				dataKey: "type"
			},
			{
				alignment: "center",
				label: <ColumnHeader>Date Taken</ColumnHeader>,
				Cell: ({ rowData: { updatedAt } }) => (
					<StyledCellWrapper center={true}>
						<StyledCellText>{updatedAt && formatDate(updatedAt)}</StyledCellText>
					</StyledCellWrapper>
				),
				ChildCell: ({ rowData: { updatedAt } }) => (
					<StyledCellWrapper center={true}>
						<StyledCellText>{updatedAt && formatDate(updatedAt)}</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "date_taken"
			},
			{
				alignment: "center",
				label: <ColumnHeader>Difficulty</ColumnHeader>,
				Cell: ({ rowData: { difficultyTierId, hasChildren } }) => (
					<StyledCellWrapper center={true}>
						<StyledCellText>{hasChildren ? "-" : QuestionDifficultyLevelTiers[difficultyTierId] ?? "-"}</StyledCellText>
					</StyledCellWrapper>
				),
				ChildCell: () => (
					<StyledCellWrapper center={true}>
						<StyledCellText>-</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "difficulty"
			},
			{
				alignment: "center",
				label: <ColumnHeader>Questions</ColumnHeader>,
				Cell: ({ rowData: { userQuestionAttempts, typeId, inProgress } }) => (
					<StyledCellWrapper center={true}>
						<StyledCellText>
							{typeId === QuestionBankTestModes.CAT && inProgress && userQuestionAttempts && userQuestionAttempts.length
								? userQuestionAttempts?.length - 1
								: userQuestionAttempts?.length || 0}
						</StyledCellText>
					</StyledCellWrapper>
				),
				ChildCell: ({ rowData: { userQuestionAttempts, typeId, inProgress } }) => (
					<StyledCellWrapper center={true}>
						<StyledCellText>
							{typeId === QuestionBankTestModes.CAT && inProgress && userQuestionAttempts && userQuestionAttempts.length
								? userQuestionAttempts?.length - 1
								: userQuestionAttempts?.length || 0}
						</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "questions"
			},
			{
				alignment: "center",
				label: <ColumnHeader>Test Duration</ColumnHeader>,
				Cell: ({ rowData: { timeSpent } }) => {
					const hours = secondsToHours(timeSpent as number);
					const hoursInSeconds = hours * 60;
					const minutes = secondsToMinutes((timeSpent as number) - hoursInSeconds);
					return (
						<StyledCellWrapper center={true}>
							<StyledCellText>{`${hours ? hours + "h" : ""} ${minutes} min`}</StyledCellText>
						</StyledCellWrapper>
					);
				},
				ChildCell: ({ rowData: { timeSpent } }) => {
					const hours = secondsToHours(timeSpent as number);
					const hoursInSeconds = hours * 60;
					const minutes = secondsToMinutes((timeSpent as number) - hoursInSeconds);
					return (
						<StyledCellWrapper center={true}>
							<StyledCellText>{`${hours ? hours + "h" : ""} ${minutes} min`}</StyledCellText>
						</StyledCellWrapper>
					);
				},
				dataKey: "duration"
			},
			{
				alignment: "center",
				label: <ColumnHeader>Score</ColumnHeader>,
				Cell: ({ rowData: { percentageGrade, passed, typeId } }) => {
					let txt;
					let clr;

					if (typeId === QuestionBankTestModes.CAT) {
						txt = passed ? "Passed" : "Failed";
						clr = passed ? theme.palette.colors.success[500] : theme.palette.colors.danger[500];
					} else {
						const { text, color } = getTestScoreText(percentageGrade!);
						clr = color;
						txt = `${text} (${Math.round((percentageGrade || 0) * 100)}%)`;
					}

					return (
						<StyledCellWrapper center={true}>
							<StyledCellText color={clr}>{txt}</StyledCellText>
						</StyledCellWrapper>
					);
				},
				ChildCell: ({ rowData: { percentageGrade, passed, typeId } }) => {
					let txt;
					let clr;

					if (typeId === QuestionBankTestModes.CAT) {
						txt = passed ? "Passed" : "Failed";
						clr = passed ? theme.palette.colors.success[500] : theme.palette.colors.danger[500];
					} else {
						const { text, color } = getTestScoreText(percentageGrade!);
						clr = color;
						txt = `${text} (${Math.round((percentageGrade || 0) * 100)}%)`;
					}

					return (
						<StyledCellWrapper center={true}>
							<StyledCellText color={clr}>{txt}</StyledCellText>
						</StyledCellWrapper>
					);
				},
				dataKey: "score"
			},
			{
				alignment: "right",
				label: "",
				width: 100,
				Cell: ({ rowData: { id, isLoadingResults, hasChildren } }) =>
					hasChildren ? (
						<></>
					) : (
						<Button
							variant={"contained"}
							color={"primary"}
							startIcon={isLoadingResults && <CircularProgress size={20} />}
							disabled={isLoadingResults}
							onClick={() =>
								getStudentTest({
									userId,
									testId: id,
									sideEffect: setPreviewData
								})
							}
						>
							Results
						</Button>
					),
				ChildCell: ({ rowData: { id, isLoadingResults } }) => (
					<Button
						variant={"contained"}
						color={"primary"}
						startIcon={isLoadingResults && <CircularProgress size={20} />}
						disabled={isLoadingResults}
						onClick={() =>
							getStudentTest({
								userId,
								testId: id,
								sideEffect: setPreviewData
							})
						}
					>
						Results
					</Button>
				)
			}
		];
	}, [
		checkboxFiltersValue,
		getStudentTest,
		getStudentTests,
		getTestScoreText,
		theme.palette.colors.danger,
		theme.palette.colors.success,
		userId
	]);

	if (isLoading) {
		return <ContentLoader />;
	}

	return (
		<Wrapper
			heading={`${firstName} ${lastName}`}
			breadcrumb={breadcrumb}
			actions={
				<Button className={classes.cancelBtn} onClick={onBack}>
					Back
				</Button>
			}
		>
			<TableContainer
				tableTitle="Tests History"
				isLoading={loadingStudentTests}
				emptyState={<EmptyState description="No Test Results available yet!" />}
				onChangePage={page => getStudentTests({ userId, page })}
				totalItems={studentTests?.totalItems as number}
				perPage={studentTests?.perPage as number}
				page={studentTests?.page as number}
			>
				<MaterialTable
					columns={tableColumns}
					hasNestedRows
					data={(studentTests?.items || []) as IUserCustomTestResponse[]}
					height={800}
				/>
			</TableContainer>
			{!isEmpty(testPreviewData) && (
				<QuizResultsContainer>
					<QuizResultsWrapper>
						<ExtendedTestResults
							testModeType={testPreviewData["typeId"]}
							userQuestion={testPreviewData["quizAttemptedQuestions"]}
							result={testPreviewData["quizResult"]}
							contentAreas={testPreviewData["contentAreas"]}
							catAttemptedQuestionsCount={testPreviewData["catAttemptedQuestionsCount"]}
							currentTest={testPreviewData["currentTest"]}
							onClose={() => setTestPreviewData({})}
							isLoading={isLoading}
							QuestionResultInfoComponent={QuestionResultInfo}
						/>
					</QuizResultsWrapper>
				</QuizResultsContainer>
			)}
		</Wrapper>
	);
};

export default StudentTestResults;
