import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { ActionMenu } from "@remar/shared/dist/components/ActionMenu";
import EmptyState from "@remar/shared/dist/components/EmptyState/EmptyState";
import HeaderWrapper from "@remar/shared/dist/components/HeaderContainer/HeaderContainer";
import { MaterialTable } from "@remar/shared/dist/components/MaterialTable";
import SearchBarComponent from "@remar/shared/dist/components/SearchBar";

import { ColumnHeader, StyledCellText, StyledCellWrapper } from "@remar/shared/dist/components/Table/styles";
import { TablePagination } from "@remar/shared/dist/components/TablePagination";
import CreateUpdateSubjectModal from "@remar/shared/dist/modals/CreateUpdateSubjectModal/CreateUpdateSubjectModal";
import DeleteModal from "@remar/shared/dist/modals/DeleteModal/DeleteModal";
import { ManageSubject } from "@remar/shared/dist/models/manageSubjects.model";

import { RootState } from "admin/src/store";

import { useDispatch, useSelector } from "react-redux";
import {
	createCommunityPoolSubject,
	deleteCommunityPoolSubject,
	deleteSubject,
	fetchSubjects,
	updateCommunityPoolSubject
} from "store/features/ManageCommunityPool/ManageCommunityPool.slice";

import { ReactComponent as IconBooks } from "assets/icons/icon-books.svg";

import { routes } from "core/constants";

import theme from "theme/default";

import { TableWrapper } from "./styles";

const breadcrumb = [
	{ title: "Dashboard", key: 0, link: "/" },
	{ title: "Community Pool", key: 1, link: routes.communityPool.getPath() },
	{ title: "Manage Subjects", key: 2 }
];
const LOCATION_ID = 2;
const ITEMS_PER_PAGE = 10;
type CreateUpdateType = ManageSubject | { id: undefined } | null;
const CommunityPoolManageSubjects: React.FC = () => {
	const [searchText, setSearchText] = useState("");
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [showDeleteModal, setShowDeleteModal] = useState<null | number>(null);
	const [createUpdateSubjectData, setCreateUpdateSubjectData] = useState<CreateUpdateType>(null);
	const {
		isLoading: subjectsLoading,
		manageCommunityPoolSubject,
		submitLoading,
		subjectDeleteLoading
	} = useSelector((state: RootState) => state.manageCommunityPool);
	const { items, totalItems } = manageCommunityPoolSubject;
	const filters: { locationId: number; name?: {} } = { locationId: LOCATION_ID };
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(
			fetchSubjects({
				perPage: ITEMS_PER_PAGE,
				page: 1,
				filters
			})
		);
	}, []);
	const handleCreateUpdateSubject = (values, { resetForm }) => {
		if (!!createUpdateSubjectData?.id) {
			dispatch(
				updateCommunityPoolSubject({
					options: {
						name: values.subjectCategoryName,
						subjectId: createUpdateSubjectData.id
					},
					callback: () => {
						setCreateUpdateSubjectData(null);
					}
				})
			);
		} else {
			dispatch(
				createCommunityPoolSubject({
					options: {
						name: values.subjectCategoryName,
						locationId: LOCATION_ID
					},
					callback: () => {
						setCreateUpdateSubjectData(null);
						resetForm();
					}
				})
			);
		}
	};
	const handleSearchBarChange = useCallback(
		searchText => {
			setCurrentPage(1);
			filters.name = { $ilike: `%${searchText}%` };
			if (searchText === "") {
				delete filters.name;
			}
			dispatch(
				fetchSubjects({
					perPage: ITEMS_PER_PAGE,
					page: 1,
					filters
				})
			);
		},
		[currentPage]
	);

	const handlePageChange = (event, page) => {
		setCurrentPage(page);
		dispatch(fetchSubjects({ perPage: ITEMS_PER_PAGE, page: page, filters }));
	};

	const handleCloseDeleteModal = () => {
		setShowDeleteModal(null);
	};

	const handleSubjectDelete = subjectId => {
		dispatch(
			deleteCommunityPoolSubject({
				options: { subjectId },
				callback: () => {
					handleCloseDeleteModal();
					dispatch(deleteSubject(subjectId));
				}
			})
		);
	};
	const tableColumns = [
		{
			alignment: "left",
			width: 50,
			label: <ColumnHeader>#</ColumnHeader>,
			Cell: ({ rowIndex }) => {
				const prevRows = (currentPage - 1) * ITEMS_PER_PAGE;
				return (
					<StyledCellWrapper>
						<StyledCellText>{rowIndex + 1 + prevRows}</StyledCellText>
					</StyledCellWrapper>
				);
			},

			dataKey: "#"
		},
		{
			alignment: "left",
			label: <ColumnHeader>Subject</ColumnHeader>,
			Cell: ({ rowData: { name } }) => (
				<StyledCellWrapper>
					<StyledCellText>{name}</StyledCellText>
				</StyledCellWrapper>
			),
			dataKey: "name"
		},
		{
			alignment: "left",
			width: 90,
			Cell: ({ rowData }) => (
				<ActionMenu
					customMenuItems={[
						{
							label: "Edit",
							onClick: () => setCreateUpdateSubjectData(rowData),
							visible: true,
							disabled: false
						},
						{
							label: "Delete",
							onClick: () => setShowDeleteModal(rowData.id),
							visible: true,
							disabled: false
						}
					]}
				/>
			),
			dataKey: "requirement"
		}
	];

	const canViewEmptyState = useMemo(
		() => items.length === 0 && !subjectsLoading && searchText === "",
		[items, subjectsLoading, searchText]
	);
	const addNewSubject = () => setCreateUpdateSubjectData({ id: undefined });
	const actions = (
		<Button variant={"contained"} color={"primary"} onClick={addNewSubject}>
			Add New Subject
		</Button>
	);
	return (
		<>
			<HeaderWrapper heading={"Manage Subjects"} breadcrumb={breadcrumb} actions={actions} />
			{canViewEmptyState ? (
				<EmptyState
					header={"Subjects"}
					description={"No Subjects added yet!"}
					icon={<IconBooks />}
					button={
						<Button variant={"contained"} color={"primary"} startIcon={<Add />} onClick={addNewSubject}>
							Add New Subject
						</Button>
					}
				/>
			) : (
				<Box pr={3}>
					<TableWrapper>
						<Box className="searchWrapper">
							<Typography>Subjects</Typography>
							<SearchBarComponent
								inputValue={searchText}
								setInputValue={setSearchText}
								onChange={handleSearchBarChange}
								isDebounced={true}
								debounceTime={1000}
								placeHolder={"Subject"}
								width={"100%"}
							/>
						</Box>
						{subjectsLoading ? (
							<Box display="flex" alignItems="center" justifyContent="center" height={500} width="100%">
								<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
							</Box>
						) : (
							<MaterialTable columns={tableColumns} data={items} height={600} />
						)}

						<TablePagination
							count={totalItems}
							page={currentPage}
							onChange={handlePageChange}
							rowsPerPage={ITEMS_PER_PAGE}
						/>
					</TableWrapper>
				</Box>
			)}

			<CreateUpdateSubjectModal
				theme={theme}
				isOpen={!!createUpdateSubjectData}
				onClose={() => setCreateUpdateSubjectData(null)}
				data={createUpdateSubjectData}
				onSubmit={handleCreateUpdateSubject}
				submitLoading={submitLoading}
			/>

			<DeleteModal
				open={!!showDeleteModal}
				title={"Delete Subject"}
				message={"Are you sure you want to delete this subject?"}
				onClose={handleCloseDeleteModal}
				onDelete={() => handleSubjectDelete(showDeleteModal)}
				cancelBtnText={"No,Cancel"}
				deleteBtnText={"Yes, Delete"}
				deleteLoading={subjectDeleteLoading}
			/>
		</>
	);
};

export default CommunityPoolManageSubjects;
