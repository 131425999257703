import React, { useCallback, useEffect, useState } from "react";

import { Box, Button, Chip, FormControl, MenuItem, Select } from "@material-ui/core";

import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { ActionMenu } from "@remar/shared/dist/components/ActionMenu";
import { Avatar } from "@remar/shared/dist/components/Avatar";
import { IColumn } from "@remar/shared/dist/components/MaterialTable";

import { ColumnHeader, StyledCellText, StyledCellWrapper } from "@remar/shared/dist/components/Table/styles";

import { EmptyState } from "@remar/shared/dist/layouts";
import WithTableContentLayout from "@remar/shared/dist/layouts/TableContentLayout";
import { ILocation } from "@remar/shared/dist/models";
import { truncate } from "lodash";

import { useHistory } from "react-router-dom";
import { AnyAction, Dispatch } from "redux";
import { fetchAllCourses, getFullState as getFullCourseState } from "store/features/Course/course.slice";
import { fetchLocations, selectManageLocationsFullState } from "store/features/ManageLocations/manageLocations.slice";

import { emit } from "store/features/notifications/notifications.slice";
import { useAppDispatch, useAppSelector } from "store/hooks";

import { routes } from "core/constants";

import AddSeats from "./AddSeats";
import { NameWrapper, StyledCellTextCentered, StyledListIcon } from "./styles";

const tableColumns = (
	currentPage: number,
	ITEMS_PER_PAGE: number,
	showAddSeatsModal: React.Dispatch<React.SetStateAction<number | null>>,
	dispatch: Dispatch<AnyAction>
): Array<IColumn<ILocation>> => {
	const baseCount = ITEMS_PER_PAGE * (currentPage - 1); // todo: strange calculation...
	return [
		{
			alignment: "left",
			label: <ColumnHeader>#</ColumnHeader>,
			Cell: ({ rowIndex }) => {
				return <StyledCellText>{baseCount + rowIndex + 1}</StyledCellText>;
			},
			width: 50,
			dataKey: "#"
		},
		{
			alignment: "left",
			label: <ColumnHeader>Name</ColumnHeader>,
			width: 350,
			Cell: ({ rowData: { admins, name, logoImageUrl, subDomainName } }) => (
				<StyledCellWrapper>
					<NameWrapper>
						<div className={"left_section"}>
							{admins.length > 0 ? (
								admins[0].admin.logo && <img alt="logo" className={"logo"} src={admins[0]?.admin.logo} />
							) : (
								<Avatar
									profileImageUrl={logoImageUrl}
									fullName={name.trim()[0].toUpperCase()}
									className={"logo"}
									variant="rounded"
									style={{ color: "#1998d5" }}
								/>
							)}
						</div>
						<div className={"right_section"}>
							<Box display="flex" gridGap={8}>
								<StyledCellText>{truncate(`${name}`, { length: 40, omission: "..." })}</StyledCellText>
								{subDomainName && (
									<Chip
										size="small"
										variant="outlined"
										label={`Subdomain: ${subDomainName}`}
										color="default"
										deleteIcon={<FileCopyOutlinedIcon />}
										onDelete={async () => {
											const host = window.location.host;
											const pattern = /^[^.]+\.(.*)/;
											const match = host.match(pattern);
											const link = `${subDomainName}.${match![1]}`;
											await navigator.clipboard.writeText(link);
											dispatch(emit({ message: `Subdomain ${link} copied to clipboard`, type: "success" }));
										}}
									/>
								)}
							</Box>
							<StyledCellText>{admins[0]?.admin.email || "N/A"}</StyledCellText>
						</div>
					</NameWrapper>
				</StyledCellWrapper>
			),
			dataKey: "name"
		},
		{
			alignment: "center",
			label: <ColumnHeader>Total Questions</ColumnHeader>,
			Cell: ({ rowData: { totalQuestions } }) => <StyledCellTextCentered>{totalQuestions}</StyledCellTextCentered>,
			dataKey: "total-questions"
		},
		{
			alignment: "center",
			label: <ColumnHeader>Number Of Students</ColumnHeader>,
			Cell: ({ rowData: { totalStudents } }) => <StyledCellTextCentered>{totalStudents ?? 0}</StyledCellTextCentered>,
			dataKey: "number-of-students"
		},
		{
			alignment: "center",
			label: <ColumnHeader>Course</ColumnHeader>,
			Cell: ({ rowData: { locationPackage } }) => (
				<StyledCellTextCentered>{locationPackage?.course.name || "N/A"}</StyledCellTextCentered>
			),
			dataKey: "course"
		},
		{
			alignment: "center",
			label: <ColumnHeader>Package Type</ColumnHeader>,
			Cell: ({ rowData: { locationPackage } }) => (
				<StyledCellTextCentered>{locationPackage?.locationPackageType.name || "N/A"}</StyledCellTextCentered>
			),
			dataKey: "pkg-type"
		},
		{
			alignment: "right",
			label: "",
			width: 100,
			Cell: ({ rowData: { id } }) => (
				<ActionMenu
					customMenuItems={[
						{
							label: "Add Seats",
							onClick: () => showAddSeatsModal(id),
							disabled: false,
							visible: true
						}
					]}
				/>
			),
			dataKey: "menu"
		}
	];
};

const breadcrumb = [
	{ title: "Dashboard", key: 0, link: "/" },
	{ title: "Manage Locations", key: 1 }
];

const ITEMS_PER_PAGE = 10;
const COURSE_FILTER_KEY = "admins.admin.subscriptions.type.allowedLocationPackages.courseId";
const initialFilters = {
	isCommon: false,
	"admins.isSuperAdmin": true,
	"admins.admin.subscriptions.hasExpired": false
};

const ManageLocations = () => {
	const history = useHistory();
	const dispatch = useAppDispatch();

	const [textSearch, setSearchText] = useState<string>("");
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [selectedCourse, setSelectedCourse] = useState<number | string>("");
	const [filtersState, setFiltersState] = useState(initialFilters);
	const [selectedLocationId, setSelectedLocationId] = useState<number | null>(null);

	const { locations, totalItems, loading } = useAppSelector(selectManageLocationsFullState);
	const { courses } = useAppSelector(getFullCourseState);

	const handleSearchBarChange = useCallback(
		searchText => {
			setCurrentPage(1);
			dispatch(
				fetchLocations({
					perPage: ITEMS_PER_PAGE,
					page: 1,
					searchKeyword: searchText,
					filters: filtersState
				})
			);
		},
		[dispatch, filtersState]
	);

	useEffect(() => {
		dispatch(
			fetchLocations({ perPage: ITEMS_PER_PAGE, page: currentPage, searchKeyword: textSearch, filters: filtersState })
		);
		dispatch(fetchAllCourses(true));
	}, [dispatch, currentPage, filtersState]);

	const handleCourseFilter = event => {
		setSelectedCourse(event.target.value);
		const filters: Record<string, string> = { [COURSE_FILTER_KEY]: event.target.value };
		setCurrentPage(1);
		if (event.target.value === "") {
			setFiltersState(initialFilters);
		} else {
			setFiltersState({ ...initialFilters, ...filters });
		}
	};

	return (
		<WithTableContentLayout
			heading="Manage Locations"
			breadcrumb={breadcrumb}
			tableTitle={`Location Accounts (${totalItems})`}
			filterBlock={
				<>
					<Box mr={1}>
						<StyledListIcon />
					</Box>
					<Box mr={2}>
						<FormControl hiddenLabel size="small" variant="filled">
							<Select
								disableUnderline
								value={selectedCourse}
								onChange={handleCourseFilter}
								displayEmpty
								MenuProps={{
									anchorOrigin: {
										vertical: "bottom",
										horizontal: "left"
									},
									transformOrigin: {
										vertical: "top",
										horizontal: "left"
									},
									getContentAnchorEl: null
								}}
							>
								<MenuItem value={""}>All</MenuItem>
								{(courses || []).map(({ id, name }) => (
									<MenuItem key={id} value={id}>
										{name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
				</>
			}
			actions={
				<Button
					variant={"contained"}
					color={"primary"}
					onClick={() => history.push(`${routes.manageLocations.getPath()}/package-overview`)}
				>
					Manage Packages
				</Button>
			}
			isLoading={loading}
			emptyState={<EmptyState placeHolder="No Locations yet" description="Location" />}
			searchText={textSearch}
			setSearchText={setSearchText}
			handleSearchBarChange={handleSearchBarChange}
			onChangePage={page => setCurrentPage(page)}
			totalItems={totalItems}
			perPage={ITEMS_PER_PAGE}
			page={currentPage}
			tableColumns={tableColumns(currentPage, ITEMS_PER_PAGE, setSelectedLocationId, dispatch)}
			data={locations}
		>
			<AddSeats locationId={selectedLocationId} handleClose={() => setSelectedLocationId(null)} />
		</WithTableContentLayout>
	);
};

export default ManageLocations;
