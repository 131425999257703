import React from "react";

import { InputAdornment, useTheme } from "@material-ui/core";

import { DatePicker } from "@remar/shared/dist/components/DatePicker";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";

import { StyledTimeIcon } from "./styles";
const TimePicker = ({ value, onChange, disabled }) => {
	const theme = useTheme<IExtendedTheme>();
	return (
		<DatePicker
			fullWidth={true}
			name="startTime"
			isTimePicker
			autoOk
			ampm={true}
			value={value}
			disabled={disabled}
			onChange={onChange}
			InputProps={{
				style: {
					cursor: "pointer",
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.colors.basic[800],
					height: 48,
					borderRadius: 4,
					padding: 10,
					width: "100%"
				},
				startAdornment: (
					<InputAdornment position="start">
						<StyledTimeIcon />
					</InputAdornment>
				),
				disableUnderline: true,
				readOnly: true
			}}
		/>
	);
};

export default TimePicker;
