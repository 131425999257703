import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Box, Button, Typography } from "@material-ui/core";

import { ActionMenu } from "@remar/shared/dist/components/ActionMenu";
import { IColumn } from "@remar/shared/dist/components/MaterialTable";
import StatusComponent from "@remar/shared/dist/components/StatusComponent";
import { ColumnHeader, StyledCellText, StyledCellWrapper } from "@remar/shared/dist/components/Table/styles";
import { EmptyState } from "@remar/shared/dist/layouts";
import WithTableContentLayout from "@remar/shared/dist/layouts/TableContentLayout";
import DeleteModal from "@remar/shared/dist/modals/DeleteModal/DeleteModal";
import { format, isAfter, isBefore } from "date-fns";

import { useDispatch, useSelector } from "react-redux";

import { RootState } from "store";
import { deleteCoupon, getCouponTypes, getCoupons } from "store/features/Coupons/coupons.slice";
import { Coupon } from "store/features/Coupons/models";

import CreateEditCoupons, {
	FormValuesType,
	PRODUCTS_OPTIONS,
	QUANTITY_OPTIONS,
	SHIPPING_PRODUCT_ID
} from "./CreateEditCoupons";

type Colors = "basic" | "primary" | "success" | "info" | "warning" | "danger";

const convertToFormData = (rowData: Coupon) => {
	const { validFrom, expiresOn } = rowData;
	const from = new Date(validFrom);
	const expires = new Date(expiresOn);
	return {
		...rowData,
		isPercentage: rowData.data.isPercentage,
		amount: rowData.data.amount,
		type: rowData.typeId,
		quantity_type: !rowData.quantity ? QUANTITY_OPTIONS.UNLIMITED : QUANTITY_OPTIONS.LIMITED,
		products_shipping: rowData.products.some(p => p.id === SHIPPING_PRODUCT_ID)
			? PRODUCTS_OPTIONS.SHIPPING
			: PRODUCTS_OPTIONS.PRODUCTS,
		courses: rowData.courses.map(c => c.id),
		validFrom: from,
		startTime: from,
		expiresOn: expires,
		endTime: expires,
		...rowData.products
			.filter(p => p.id !== SHIPPING_PRODUCT_ID)
			.reduce((acc, i) => Object.assign(acc, { [`product-${i.id}`]: true }), {})
	};
};

const CouponStatus = ({ validFrom, expiresOn }) => {
	const currentDate = new Date();
	const validFromDate = new Date(validFrom);
	const expiresOnDate = new Date(expiresOn);
	const active = isBefore(validFromDate, currentDate) && isAfter(expiresOnDate, currentDate);
	const Scheduled = isAfter(validFromDate, currentDate);
	const Expired = isAfter(expiresOnDate, currentDate);
	const { color, text } = useMemo((): { color: Colors; text: string } => {
		if (active) {
			return { color: "success", text: "Active" };
		} else if (Scheduled) {
			return { color: "warning", text: "Scheduled" };
		} else if (Expired) {
			return { color: "danger", text: "Expired" };
		} else {
			return { color: "danger", text: "Expired" };
		}
	}, [active, Scheduled, Expired]);

	return <StatusComponent color={color} text={text} />;
};

const Coupons = () => {
	const [showCreateEditCoupons, setShowCreateEditCoupons] = useState<null | FormValuesType>(null);
	const [searchText, setSearchText] = useState("");
	const [showDeleteModal, setShowDeleteModal] = useState<null | number>(null);
	const dispatch = useDispatch();
	const { Coupons, couponTypes, couponsLoading, loadDeleteCoupon } = useSelector((state: RootState) => state.coupons);

	const handleSearchBarChange = useCallback(
		searchText => {
			dispatch(getCoupons({ page: 1, searchText }));
		},
		[dispatch]
	);

	useEffect(() => {
		dispatch(getCouponTypes());
		dispatch(getCoupons({}));
	}, [dispatch]);

	const { couponsList, page, perPage, totalItems, totalCount } = useMemo(
		() => ({
			couponsList: Coupons?.items ?? [],
			page: Coupons?.page ?? 1,
			perPage: Coupons?.perPage ?? 10,
			totalItems: Coupons?.totalItems ?? 0,
			totalCount: Coupons?.totalCount ?? 0
		}),
		[Coupons]
	);

	const tableColumns: Array<IColumn<Coupon>> = useMemo(
		() => [
			{
				alignment: "left",
				label: <ColumnHeader>Name</ColumnHeader>,
				Cell: ({ rowData: { name } }) => (
					<StyledCellWrapper>
						<StyledCellText>{name}</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "name"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Coupon Code</ColumnHeader>,
				Cell: ({ rowData: { code } }) => (
					<StyledCellWrapper>
						<StyledCellText>{code}</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "code"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Status & Expiration</ColumnHeader>,
				Cell: ({ rowData: { validFrom, expiresOn, neverExpire } }) => (
					<StyledCellWrapper>
						<Box display={"flex"} flexDirection={"column"}>
							<CouponStatus validFrom={validFrom} expiresOn={expiresOn} />
							<Box display={"flex"} alignItems={"center"} ml={2}>
								<Box>
									<StyledCellText>{format(new Date(validFrom), "d MMM yyyy")}</StyledCellText>
									<StyledCellText>{format(new Date(validFrom), "hh:mm aa")}</StyledCellText>
								</Box>
								<span style={{ marginLeft: 5, marginRight: 5 }}>-</span>
								<Box>
									{neverExpire ? (
										<StyledCellText>Never Expire</StyledCellText>
									) : (
										<>
											<StyledCellText>{format(new Date(expiresOn), "d MMM yyyy")}</StyledCellText>
											<StyledCellText>{format(new Date(expiresOn), "hh:mm aa")}</StyledCellText>
										</>
									)}
								</Box>
							</Box>
						</Box>
					</StyledCellWrapper>
				),
				dataKey: "status"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Discount</ColumnHeader>,
				Cell: ({
					rowData: {
						data: { isPercentage, amount }
					}
				}) => (
					<StyledCellWrapper>
						<StyledCellText>{`${!isPercentage ? "$" : ""}${amount}${isPercentage ? "%" : ""}`}</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "discount"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Type</ColumnHeader>,
				Cell: ({ rowData: { typeId } }) => {
					const Type = couponTypes ? couponTypes.items.find(c => c.id === typeId)?.name : "";
					return (
						<StyledCellWrapper>
							<StyledCellText>{Type}</StyledCellText>
						</StyledCellWrapper>
					);
				},
				dataKey: "type"
			},
			{
				alignment: "left",
				label: <ColumnHeader>products</ColumnHeader>,
				Cell: ({ rowData: { products } }) => (
					<StyledCellWrapper>
						<StyledCellText>
							{products.map((p, index) => (
								<Typography variant={"caption"} key={p.id}>
									{p.name}
									{index !== products.length - 1 ? <span style={{ marginRight: "5px" }}>,</span> : null}
								</Typography>
							))}
						</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "product"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Quantity</ColumnHeader>,
				Cell: ({ rowData: { quantity } }) => (
					<StyledCellWrapper>
						<StyledCellText>{quantity ? quantity : "Unlimited"}</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "quantity"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Used</ColumnHeader>,
				Cell: ({ rowData: { totalUsed } }) => (
					<StyledCellWrapper>
						<StyledCellText>{totalUsed}</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "used"
			},
			{
				alignment: "right",
				label: "",
				width: 100,
				Cell: ({ rowData }) => (
					<ActionMenu
						customMenuItems={[
							{
								label: "Edit",
								onClick: () => setShowCreateEditCoupons(convertToFormData(rowData)),
								visible: true,
								disabled: false
							},
							{
								label: "Deactivate",
								onClick: () => {},
								visible: true,
								disabled: false
							},
							{
								label: "Delete",
								onClick: () => {
									setShowDeleteModal(rowData.id);
								},
								visible: true,
								disabled: false
							}
						]}
					/>
				),
				dataKey: "menu"
			}
		],
		[couponTypes]
	);

	return (
		<>
			<WithTableContentLayout
				heading="Coupons"
				isLoading={couponsLoading}
				actions={
					<Box>
						<Button
							color={"primary"}
							variant={"contained"}
							onClick={() => setShowCreateEditCoupons({} as FormValuesType)}
						>
							Create New Coupon
						</Button>
					</Box>
				}
				emptyState={<EmptyState description="No Coupons found" />}
				onChangePage={page => {
					dispatch(getCoupons({ page }));
				}}
				totalItems={totalItems}
				totalEntities={totalCount}
				tableTitle="Coupons"
				perPage={perPage}
				page={page}
				searchText={searchText}
				setSearchText={setSearchText}
				handleSearchBarChange={handleSearchBarChange}
				tableColumns={tableColumns}
				data={couponsList}
			/>
			{showCreateEditCoupons && (
				<CreateEditCoupons initObj={showCreateEditCoupons} onClose={() => setShowCreateEditCoupons(null)} />
			)}
			<DeleteModal
				open={!!showDeleteModal}
				title={"Delete Coupon"}
				message={"Are you sure you want to delete this coupon?"}
				onClose={() => setShowDeleteModal(null)}
				onDelete={() => {
					(dispatch(deleteCoupon(showDeleteModal as number)) as unknown as Promise<void>).then(() => {
						setShowDeleteModal(null);
					});
				}}
				cancelBtnText={"No,Cancel"}
				deleteBtnText={"Yes, Delete"}
				deleteLoading={loadDeleteCoupon}
			/>
		</>
	);
};

export default Coupons;
